import { Injectable } from '@angular/core';
import { HttpHandlerService } from './http-handler.service';
import { Subject, Observable } from 'rxjs';

import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { deleteApp } from 'firebase/app';
import { FirebaseService } from './firebase.service';


// if(localStorage.getItem('1')=='1'){
//   const firebaseConfig = {
//     apiKey: 'AIzaSyBEDQiy7UKIaUaq74_bjfWc7AiI93W_GwA',
//     authDomain: 'icl-cpfc-910f0.firebaseapp.com',
//     projectId: 'icl-cpfc-910f0',
//     storageBucket: 'icl-cpfc-910f0.appspot.com',
//     messagingSenderId: '62718340235',
//     appId: '1:62718340235:web:02696196c7e5442f1df88e',
//     measurementId: 'G-ZK6KR5G4K5'
//   }
//   const app1 = initializeApp(firebaseConfig);
// getAnalytics(app1);

// }
// if(localStorage.getItem('2')=='2'){
//   const firebaseConfigPericlass= {
//     apiKey: "AIzaSyA2ZyA2ilPfwosaBMy4wZ___PsHTyiLARU",
//   authDomain: "cpfc-periclass.firebaseapp.com",
//   projectId: "cpfc-periclass",
//   storageBucket: "cpfc-periclass.appspot.com",
//   messagingSenderId: "343343917359",
//   appId: "1:343343917359:web:2c92f090ac78b974bc19da",
//   measurementId: "G-Q4T45PVXLZ"
//   }
//   const app = initializeApp(firebaseConfigPericlass);
// getAnalytics(app);

// }




@Injectable({
  providedIn: 'root'
})
export class ExportService {
  private projectSource = new Subject<any>();
  public projectSource$ = this.projectSource.asObservable();
  private exportsSource = new Subject<any>();
  public exportsSource$ = this.exportsSource.asObservable();
  private exportList;
  private supplyList;
  private supplyImagesList;
  private galleryDetails;
  private plant_id = null;
  private plant_name: string = "";
  private newExportList = [];
  private loginSource = new Subject<any>();
  public loginSource$ = this.loginSource.asObservable();
  public app1;
  public firebase_service;
  constructor(private httpHandler: HttpHandlerService,private firebase: FirebaseService) {
    this.firebase_service=firebase;
    if(!this.plant_id){
      this.plant_id = localStorage.getItem('plant_id');
    }
    if(!this.plant_name){
      this.plant_name = localStorage.getItem('plant_name') ? localStorage.getItem('plant_name') : "";
    }
  
  }

  getPlantName(){
    return this.plant_name;
  }
  getExports(status): void { /// status - {status: 0/1} - office -1
    // tslint:disable-next-line: deprecation
    this.getExportsHttpRequest({"status": status, "plant_id": this.plant_id}).subscribe((data) => { // plant_id - 1 - neot_hovav | 2 - peri_class
      this.exportList = data;
      if (status === 0) {
        this.typeAdjustment();
      }
      this.supplyAdjustment();
    });
  }

  // tslint:disable-next-line: typedef
  supplyAdjustment() {
    this.newExportList = [];
    const dict = {};
    let arr;
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.exportList.length; i++) {
      arr = dict[this.exportList[i].export_id] !== undefined ? dict[this.exportList[i].export_id] : [];
      arr.push(this.exportList[i].supply_id);
      dict[this.exportList[i].export_id] = arr;
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.exportList.length; i++) {
      const t = this;
      // tslint:disable-next-line: typedef
      // tslint:disable-next-line: only-arrow-functions
      const firstExport = this.newExportList.findIndex(function (ex) {
        return ex.export_id == t.exportList[i].export_id;
      });
      if (firstExport == -1) {
        // tslint:disable-next-line: max-line-length
        const obj = { id: this.exportList[i].id, export_id: this.exportList[i].export_id, supply_id: dict[this.exportList[i].export_id], due_date: this.exportList[i].due_date, type: this.exportList[i].type };
        this.newExportList.push(obj);
        // this.newExportList = [];
      }
    }
    this.exportsSource.next(this.newExportList);

  }

  // tslint:disable-next-line: typedef
  typeAdjustment() {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.exportList.length; i++) {
      switch (this.exportList[i].type) {
        case 1:
          this.exportList[i].type = 'נוזלים';
          break;
        case 2:
          this.exportList[i].type = 'מוצקים';
          break;
        case 3:
          this.exportList[i].type = 'טטרא';
          break;
          case 4:
            this.exportList[i].type = 'אשלג טהור';
            break;
            case 5:
              this.exportList[i].type = 'מלחי זרחן';
              break;
              case 6:
              this.exportList[i].type = 'חומצה לבנה';
              break;
      }
    }
  }

  getExportsHttpRequest(data): Observable<any> {
    return this.httpHandler.post('export/byStatus', data);
  }

  // getSupply(exportId): void {
  //   // tslint:disable-next-line: deprecation
  //   this.getSupplyHttpRequest(exportId).subscribe((data) => {

  //     this.supplyList = data;
  //     this.projectSource.next(this.supplyList);
  //   });
  //   return this.httpHandler.post('export/byStatus', { status });

  // }

  getSupplyHttpRequest(data): Observable<any> {
    return this.httpHandler.post('export/byExportId', data);
  }

  getSupplyImages(supplyId): void {
    // tslint:disable-next-line: deprecation
    this.getSupplyImagesHttpRequest(supplyId).subscribe((data) => {
      this.supplyImagesList = data;
      this.projectSource.next(this.supplyImagesList);
    });
  }

  getSupplyImagesHttpRequest(id): Observable<any> {
    return this.httpHandler.post('images/bySupplyId', { id });
  }

  creatGallery(uniqueGalleryKey, exportNumber, supplyNumber, supplyNumbers, supplyImages, imagesPath): Observable<any> {
    // tslint:disable-next-line: max-line-length
    return this.httpHandler.post('gallery/creatGallery', { uniqueGalleryKey, exportNumber, supplyNumber, supplyNumbers, supplyImages, imagesPath });
  }

  getGalleryDetails(uniqueGalleryKey): void {
    // tslint:disable-next-line: deprecation
    this.getGalleryDetailsHttpRequest(uniqueGalleryKey).subscribe((data) => {
      this.galleryDetails = data;
      this.projectSource.next(this.galleryDetails);
    });
  }

  getGalleryDetailsHttpRequest(uniqueGalleryKey): Observable<any> {
    return this.httpHandler.post('gallery/getGalleryDetails', { uniqueGalleryKey });
  }

  // tslint:disable-next-line: typedef
  postFile(formData) {
    return this.httpHandler.post('files/upload', formData);
  }

  // tslint:disable-next-line: typedef
  login(x) {
    // tslint:disable-next-line: deprecation
    this.httpHandler.post('user-login/login', x).subscribe((res: any) => {
      console.log(res)
      if (res[0]) {
        if(res[0].plant_id==4){
          localStorage.setItem('plant_4','4');
        }
        else{
          localStorage.setItem('plant_4','0');
        }
        if(res[0].plant_id==5){
          localStorage.setItem('plant_5','5');
        }
        else{
          localStorage.setItem('plant_5','0');
        }
        
        console.log(this.plant_id)
    
        localStorage.setItem('plant_id', res[0].plant_id);
        localStorage.setItem('plant_name', res[0].plant_name);
        this.plant_id = res[0].plant_id;
        this.plant_name = res[0].plant_name;
      
        this.firebase_service.initFirebase(res[0].plant_id)
        if (res[0].permission === 1) {
          localStorage.setItem('loginUser', '629e254fa02175b06effbea616b0fcb4');
          this.loginSource.next(res);
        } else if (res[0].permission === 0) {
          localStorage.setItem('loginUser', '7ebe92939bb0767f882fd5647524b8c7');
          this.loginSource.next(res);
        }
      }
      else {
        this.logOut();
        this.loginSource.next(res);
        // alert("wrong username or password");
      }
    });
  }

  // tslint:disable-next-line: typedef
  logOut() {
    deleteApp(this.app1);
    localStorage.removeItem('loginUser');
  }

  // tslint:disable-next-line: typedef
  transferenceUpdateExport(data) {
    return this.httpHandler.post('export/transferenceUpdateExport', data);
  }
  transferenceSupplyExportIdcont(data) {
    return this.httpHandler.post('cont-images/transferenceSupplyExportId', data);
  }
  transferenceUpdateExportImages(data) {
    return this.httpHandler.post('images/transferenceSupplyUpdateExportId', data);
  }

  // tslint:disable-next-line: typedef
  transferenceUpdateSupply(data) {
    return this.httpHandler.post('export/transferenceUpdateSupply', data);
  }

  // tslint:disable-next-line: typedef
  transferenceUpdateSupplyContImg(data) {
    return this.httpHandler.post('cont-images/transferenceUpdateSupply', data);
  }

  // tslint:disable-next-line: typedef
  transferenceUpdateSupplyImg(data) {
    return this.httpHandler.post('images/transferenceUpdateSupply', data);
  }

    // tslint:disable-next-line: typedef
    transferenceSupplyUpdateForDuplicateSupply(data) {
      return this.httpHandler.post('export/transferenceSupplyUpdateForDuplicateSupply', data);
    }
  
    // tslint:disable-next-line: typedef
    transferenceSupplyUpdateForDuplicateSupplyContImg(data) {
      return this.httpHandler.post('cont-images/transferenceSupplyUpdateForDuplicateSupply', data);
    }
  
    // tslint:disable-next-line: typedef
    transferenceSupplyUpdateForDuplicateSupplyImg(data) {
      return this.httpHandler.post('images/transferenceSupplyUpdateForDuplicateSupply', data);
    }

  // tslint:disable-next-line: typedef
  approveAllExport(exportNum) {
    return this.httpHandler.post('export/approveAllExport', { exportNum });
  }

  // tslint:disable-next-line: typedef
  approvePartExport(supplyNum) {
    return this.httpHandler.post('export/approvePartExport', { supplyNum });
  }

  // tslint:disable-next-line: typedef
  deleteImgHttpRequest(path) {
    return this.httpHandler.post('images/deleteImage', { path });
  }
}
