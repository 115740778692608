<div class="supply">
    <app-header></app-header>
    <div class="container-fluid m-3">
        <div class="row" style="text-align: initial;">
            <button class="btn btn-icon btn-default m-5 my-4 text-color" type="button" (click)="downloadAll()">
                download all
            </button>
        </div>
        <div class="row text-center galleryRow">
            <div class="gallery-container col-md- p-4" *ngFor="let imgPath of imagesList ; let i = index">
                <img class="img-supply img-cursor" id={{i}} [src]="imgPath" />
                <div class="img-bottom">
                    <div class="mt-2">
                        <button class="btn icon-btn btn-default" type="button" (click)="openFullScreen(imgPath)">
                            <i class="fas fa-expand-arrows-alt" style="margin: 5px 0;"></i>
                        </button>
                            <button class="btn icon-btn btn-default" type="button" (click)="downloadImg(imgPath)">
                            <i class="fas fa-download" style="margin: 5px 0;">
                            </i>
                        </button>
                      
                    </div>
                    <div class="img-name">
                        <a>{{getImgName(imgPath)}}</a>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="viewerOpen" class="viewer-container">
            <div class="viewer-content text-center">
                <img class="img-supply" [src]="selectedImgFullScreen" />
                <span class="material-icons close-img" (click)="viewerOpen = false">close</span>
            </div>
        </div>
    </div>

</div>