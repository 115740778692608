import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AdminComponent } from './pages/admin/admin.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ToastrModule } from 'ngx-toastr';
import { NgxLoadingModule } from 'ngx-loading';

import { MatPaginatorIntlCro } from '../app/customClass';
import { PopupComponent } from './components/popup/popup.component';
import { PopupApproveComponent } from './components/popup-approve/popup-approve.component';
import { LoginComponent } from './pages/login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { ChooseSupplyComponent } from './pages/choose-supply/choose-supply.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { PopupAddImageComponent } from './components/popup-add-image/popup-add-image.component';
import { SupplyGalleryComponent } from './pages/supply-gallery/supply-gallery.component';
import { OfficeComponent } from './pages/office/office.component';
import { ChooseSupplyOfficeComponent } from './pages/choose-supply-office/choose-supply-office.component';
import { sendChoiceComponent } from './pages/send-choice/send-choice.component';
import { HttpClientModule } from '@angular/common/http';
import { MailGalleryComponent } from './pages/mailGallery/mailGallery.component';
import { MailGallerySupplyComponent } from './pages/mail-gallery-supply/mail-gallery-supply.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { transferenceData } from 'src/app/classes/Transfer';
import { ExportService } from './services/export.service';
import { ImageMagnifierComponent } from './components/image-magnifier/image-magnifier.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    PopupComponent,
    PopupApproveComponent,
    LoginComponent,
    ChooseSupplyComponent,
    FooterComponent,
    HeaderComponent,
    PopupAddImageComponent,
    SupplyGalleryComponent,
    OfficeComponent,
    ChooseSupplyOfficeComponent,
    sendChoiceComponent,
    MailGalleryComponent,
    MailGallerySupplyComponent,
    ImageMagnifierComponent
  ],
  imports: [
    BrowserModule,
    NgxDatatableModule,
    BrowserAnimationsModule,
    MatPaginatorModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    Ng2ImgMaxModule,
    MatIconModule,
    MatButtonModule,
    MatSortModule,
    MatTooltipModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxLoadingModule.forRoot({}),
    ToastrModule.forRoot(),
  ],
  providers: [{ provide: MatPaginatorIntl, useClass: MatPaginatorIntlCro },transferenceData,ExportService],
  bootstrap: [AppComponent]
})
export class AppModule { }
