import { Component, OnInit, ApplicationRef, Renderer2 } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExportService } from 'src/app/services/export.service';
import * as fileSaver from 'file-saver';
import { HttpClient } from '@angular/common/http';



@Component({
  // tslint:disable-next-line: component-selector
  selector: 'mail-gallery',
  templateUrl: './MailGallery.component.html',
  styleUrls: ['./MailGallery.component.scss']
})
export class MailGalleryComponent implements OnInit {
  imagesList: any;
  viewerOpen = false;
  selectedImgFullScreen;
  popupAddImgIsOpen = false;
  public popupApproveIsOpen = false;

  // tslint:disable-next-line: max-line-length
  constructor(private ref: ApplicationRef, private route: ActivatedRoute, private exportsService: ExportService, private http: HttpClient) { }

  ngOnInit(): void {
    // tslint:disable-next-line: deprecation
    this.route.queryParams.subscribe((params) => {
      // tslint:disable-next-line: max-line-length
      if (params.gid) { // not all images of supply - get the record by gid from gallery table and show the images that saved there in imagesPaths
        this.exportsService.getGalleryDetails(params.gid);
        // tslint:disable-next-line: deprecation
        this.exportsService.projectSource$.subscribe((data) => {
          const allObject = data[0];
          if (allObject) {
            this.imagesList = this.stringToArray(allObject.imagesPath);
          }
        });
      } else { // enter from mailGallerySupply with the supply number - get all the images of the supply
        this.exportsService.getSupplyImages(params.supplyId);
        // tslint:disable-next-line: deprecation
        this.exportsService.projectSource$.subscribe((data) => {
          if (data) {
            this.imagesList = data.map(x => x.path);
          }
        });
      }
    });
  }

  // tslint:disable-next-line: typedef
  stringToArray(imagesPath) {
    return imagesPath.split(',');
  }
  // tslint:disable-next-line: typedef
  openFullScreen(img) {
    this.viewerOpen = true;
    this.selectedImgFullScreen = img;
    this.ref.tick();
  }
  // tslint:disable-next-line: typedef
  getImgName(x) {
    if (x) {
      let temp = x.split('.')[0].split('-');
      temp = temp[temp.length - 1];
      return temp;
    }
  }

  // tslint:disable-next-line: typedef
  downloadImage(img) {
    window.open(img);
  }

  // tslint:disable-next-line: typedef
  addImage() {
    this.popupAddImgIsOpen = true;
  }

  // tslint:disable-next-line: typedef
  closePopup() {
    this.popupAddImgIsOpen = false;
    this.popupApproveIsOpen = false;
  }

  // tslint:disable-next-line: typedef
  approvePartExport() {
    this.popupApproveIsOpen = true;
  }

  // tslint:disable-next-line: typedef
  downloadImg(x) {
    const z = x.split('/');
    const name = z[z.length-1];
    this.http.get(x, {
      observe: 'response',
      responseType: 'blob'
    }).subscribe(res => {
      // this.fileSaver.save(res.body, "698656-58585-3846.jpg");
      fileSaver.saveAs(res.body, name);
    });
    return;
  }
  downloadAll(){
    this.imagesList.forEach(element => {
      this.downloadImg(element)
    });
  }
}

