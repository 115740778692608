import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ExportService } from 'src/app/services/export.service';
import { transferenceData } from 'src/app/classes/Transfer';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
  @Output() closePopup = new EventEmitter<any>();
  focus;
  focus1;
  focus2;
  focus3;
  focus4;
  focus5;

  currentExportNum:string;
  newExportNum:string;
  currentSupplyNum:string;
  newSupplyNum:string;
  plant_4;
  plant_5;


  constructor(private exportsService: ExportService, public transferenceData: transferenceData) { }

  ngOnInit(): void {
    if(localStorage.getItem('plant_4')=='4')
    this.plant_4='4';
    else
    this.plant_4='0';
    console.log(this.plant_5);
    if(localStorage.getItem('plant_5')=='5')
    this.plant_5='5';
    else
    this.plant_5='0';
  }
  

  // tslint:disable-next-line: typedef
  close() {
    this.closePopup.emit();
  }

  // tslint:disable-next-line: typedef
  popupClicked() {
    // tslint:disable-next-line: deprecation
    event.stopPropagation();
  }

  // tslint:disable-next-line: typedef
  async transferenceFunc() {
   this.transferenceData = {
      currentExportNum: this.currentExportNum.toString(),
      newExportNum: this.newExportNum.toString(),
      currentSupplyNum: this.currentSupplyNum?this.currentSupplyNum.toString():null,
      newSupplyNum: this.newSupplyNum? this.newSupplyNum.toString():null
    };

    if (this.transferenceData.currentSupplyNum===null|| this.transferenceData.newSupplyNum===null){
    await this.exportsService.transferenceUpdateExport(this.transferenceData).subscribe(async() => {});
   
    // this.closePopup.emit();
            //  window.location.reload();
    }
  
     
      await this.exportsService.transferenceUpdateSupply(this.transferenceData).subscribe(async () => {
        // tslint:disable-next-line: deprecation
        await this.exportsService.transferenceUpdateSupplyContImg(this.transferenceData).subscribe(async () => {
          // tslint:disable-next-line: deprecation
          await this.exportsService.transferenceUpdateSupplyImg(this.transferenceData).subscribe(async() => {
            this.closePopup.emit();
             window.location.reload();
          });
        });
      });
         
}

//for duplicate supplys like rotem factory
async transferenceFuncRotem() {
  this.transferenceData = {
     currentExportNum: this.currentExportNum.toString(),
     newExportNum: this.newExportNum.toString(),
     currentSupplyNum: this.currentSupplyNum?this.currentSupplyNum.toString():null,
     newSupplyNum: this.newSupplyNum? this.newSupplyNum.toString():null
   };

   if (this.transferenceData.currentSupplyNum===null|| this.transferenceData.newSupplyNum===null){
   await this.exportsService.transferenceUpdateExport(this.transferenceData).subscribe(async() => {
     await this.exportsService.transferenceSupplyExportIdcont(this.transferenceData).subscribe(async() => {
    await this.exportsService.transferenceUpdateExportImages(this.transferenceData).subscribe(async() => {});});});
  
   
   }
 
    
     await this.exportsService.transferenceSupplyUpdateForDuplicateSupply(this.transferenceData).subscribe(async () => {
       // tslint:disable-next-line: deprecation
       await this.exportsService.transferenceSupplyUpdateForDuplicateSupplyContImg(this.transferenceData).subscribe(async () => {
         // tslint:disable-next-line: deprecation
         await this.exportsService.transferenceSupplyUpdateForDuplicateSupplyImg(this.transferenceData).subscribe(async() => {
           this.closePopup.emit();
            window.location.reload();
         });
       });
     });
        
}

}
