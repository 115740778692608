import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {


  clear(): void {
    localStorage.clear();
  }

  getItem(key: string): string | null {
    try {
      return localStorage.getItem(key);
    } catch (err) {
      console.error(err);
    }
  }

  setItem(key: string, value: string): void {
    return localStorage.setItem(key, value);
  }

  constructor() {
  }
}
