<div class="supply">
    <app-header></app-header>
    <div [ngClass]="{'sdom':this.plant_4=='4'}" class="container-fluid m-3">
        <div class="row m-3">
            <a *ngIf="this.plant_4!='4'" class="btn btn-outline-secondary text-white m-1" (click)="creatNewGallery(null)">
                Send
                <span *ngIf="numSelected() > 0">{{numSelected()}}</span>
            </a>
            <button *ngIf="this.plant_4!='4'" type="button" class="btn btn-outline-secondary text-white m-1" (click)="selectAllImg()">
                {{selectAllText}}</button>
                <a *ngIf="this.plant_4=='4'" class="btn btn-outline-secondary text-white m-1" (click)="creatNewGallery(null)">
                    שלח
                    <span *ngIf="numSelected() > 0">{{numSelected()}}</span>
                </a>
                <button *ngIf="this.plant_4=='4'" type="button" class="btn btn-outline-secondary text-white m-1" (click)="selectAllImg()">
                   בחר הכל</button>
            <p *ngIf="this.plant_4!='4'" class="select-title">SELECT CONTAINER PHOTO</p>
            <p *ngIf="this.plant_4=='4'" class="select-title">בחר תמונות מהאצווה</p>
            
        </div>
        <div  class="row text-center galleryRow">
            <fieldset id="capacity" *ngFor="let img of listData ; let i = index">
                <input [(ngModel)]="selectedRed" id="{{i}}" type="checkbox" name="capacity"
                    [checked]="listData[i].selected" (change)="onImageSelect(i)" />
                <label class="img-s" [class.active]="listData[i].selected" for="{{i}}">
                    <img class="img-supply img-cursor" [src]="img.path" />
                    <div class="img-bottom">
                        <div class="mt-2">
                            <button class="btn icon-btn btn-default" type="button" (click)="openFullScreen(img.path)">
                                <i class="fas fa-expand-arrows-alt" style="margin: 5px 0;"></i>
                            </button>
                        </div>
                        <div class="img-name">
                            <a>{{getImgName(img.path)}}</a>
                        </div>
                    </div>
                </label>
                <br>
                <!-- {{selectedRed}} -->
            </fieldset>

        </div>

        <div *ngIf="viewerOpen" class="viewer-container">
            <div class="viewer-content text-center">
                <img class="img-supply" [src]="selectedImgFullScreen" />
                <span class="material-icons close-img" (click)="viewerOpen = false">close</span>
            </div>
        </div>
    </div>
    <app-footer [pageName]="pageName" BackToPrevUrl="/chooseSupplyOffice" adminOrOffice="office"
        (sendExport)="sendEntireExport()"></app-footer>
    <app-popup-add-image *ngIf="popupAddImgIsOpen" (closePopup)="closePopup()" [pageName]="pageName">
    </app-popup-add-image>
    <app-popup-approve *ngIf="popupApproveIsOpen" (closePopup)="closePopup()" [textPopup]="textPopup">
    </app-popup-approve>
</div>