import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AwsAuthService } from 'src/app/services/aws-auth.service';
import { ExportService } from 'src/app/services/export.service';
import { getAnalytics, logEvent } from "firebase/analytics";

import { initializeApp } from "firebase/app";
import { environment} from 'src/environments/environment';
import { AdalLoginService } from 'src/app/services/adal-login.service';







// Amplify.configure({
//   Auth: {
//     region: 'eu-west-1',
//     UserPoolId: 'eu-west-1_oHkDdVZyn',
//     ClientId: '6alh70v09ahhastmm05hdk8aki'
//   }
// });

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  focus;
  focus1;
  public name: string;
  public password: string;
  public showErrorMsg = false;
  env;
  firebaseConfig
  
  

  constructor(private router: Router, private exportsService: ExportService, private awsAuthService: AwsAuthService,private adal:AdalLoginService) {
    // tslint:disable-next-line: no-string-literal
    // const analytics = getAnalytics();
    
    // if(localStorage.getItem('plant_id')=='1')
    // {
    //   this.env=Keys.naot;
    // }
    // else if(localStorage.getItem('plant_id')=='2')
    // {
    //   this.env=Keys.Periclass;
    // }
    // else if(localStorage.getItem('plant_id')=='3')
    // {
    //   this.env=Keys.Ipt;
    // }
    // else if(localStorage.getItem('plant_id')=='4')
    // {
    //   this.env=Keys.Sdom;
    // }
    
    // const app1 = initializeApp(this.env);
    // getAnalytics(app1);
if(adal.isLoggedIn)
{
    if (localStorage.getItem('loginUser') === '629e254fa02175b06effbea616b0fcb4') {
      this.router.navigateByUrl('/admin');
    } else if (localStorage.getItem('loginUser') === '7ebe92939bb0767f882fd5647524b8c7') {
      this.router.navigateByUrl('/office');
    }
  }
    // tslint:disable-next-line: deprecation
    this.exportsService.loginSource$.subscribe((data) => {
      // data && data.plant_id ? this.initFirebase(data.plant_id):""
      if (data[0] && data[0].permission === 1) {
        this.showErrorMsg = false;
        // logEvent(analytics, 'start_cpfc_admin', {});
        this.router.navigateByUrl('/admin');
      } else if (data[0] && data[0].permission === 0) {
        this.showErrorMsg = false;
        // logEvent(analytics, 'start_cpfc_office', {});
        this.router.navigateByUrl('/office');
      } else {
        this.showErrorMsg = true;
      }
    });
  }

  ngOnInit(): void {

  }


  // tslint:disable-next-line: typedef
  login() {
    if (this.name === undefined || this.password === undefined) {
      this.showErrorMsg = true;
    } else {
      const user = {
        username: this.name,
        password: this.password,
      };
      // this.loginService.login(user);
      this.exportsService.login(user);
    }

    // COGNITO
    // this.awsAuthService.signIn(
    //   this.name,
    //   this.password,
    //   async (res) => {
    //     console.log(res);
    //     //this.router.navigate(['/admin']);
    //   },
    //   (err) => {
    //     console.log(err);
    //     if (err.internalMessage === 'New password is required.') {
    //       console.log('New password is required.');
    //     }
    //     else {
    //       if (err.internalMessage && err.internalMessage.includes('Incorrect username or password')) {
    //         console.log('Incorrect username or password');
    //       }
    //     }
    //   }
    // );
  }
  // initFirebase(plantId){
  //   if(plantId=='1')
  //   {
     
  //     var env=environment.firebaseConfig.firebaseConfigNaot
  //   }
  //   else if(plantId=='2')
  //   {
  //      env=environment.firebaseConfig.firebaseConfigPericlass
  //   }
  //   else if(plantId=='3')
  //   {
  //     env=environment.firebaseConfig.firebaseConfigIpt
  //   }
  //   else if(plantId=='4')
  //   {
  //     env=environment.firebaseConfig.firebaseConfigSdom
  //   }
  //   const app = initializeApp(env);
  //   getAnalytics(app);

    


    
  // }
}

