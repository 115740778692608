<div class="popup-wrap" (click)="close()">
    <div class="modal-dialog" (click)="popupClicked()">
        <div class="modal-content">
            <div class="modal-header">
                <h3 class="mb-0">העברות</h3>
            </div>
            <div class="modal-body text-center">
                <form>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" [ngClass]="{ focused: focus === true }">
                                <div class=" input-group input-group-merge">
                                    <input *ngIf="(this.plant_4!='4'&&this.plant_5!='5')" class="form-control" placeholder="מספר יצוא חדש" type="string"
                                        onkeydown="return event.keyCode !== 69" (focus)="focus = true"
                                        (blur)="focus = false" [(ngModel)]="newExportNum" name="newExportNum" />
                                        <input *ngIf="(this.plant_4=='4'||this.plant_5=='5')" class="form-control" placeholder="מספר הזמנה חדש" type="string"
                                        onkeydown="return event.keyCode !== 69" (focus)="focus = true"
                                        (blur)="focus = false" [(ngModel)]="newExportNum" name="newExportNum" />
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group" [ngClass]="{ focused: focus1 === true }">
                                <div class=" input-group input-group-merge">
                                    <input *ngIf="(this.plant_4!='4'&&this.plant_5!='5')" class="form-control" placeholder="מספר יצוא קיים" type="string"
                                        onkeydown="return event.keyCode !== 69" (focus)="focus1 = true"
                                        (blur)="focus1 = false" [(ngModel)]="currentExportNum"
                                        name="currentExportNum" />
                                        <input *ngIf="(this.plant_4=='4'||this.plant_5=='5')" class="form-control" placeholder="מספר הזמנה קיים" type="string"
                                        onkeydown="return event.keyCode !== 69" (focus)="focus1 = true"
                                        (blur)="focus1 = false" [(ngModel)]="currentExportNum"
                                        name="currentExportNum" />

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" [ngClass]="{ focused: focus2 === true }">
                                <div class=" input-group input-group-merge">
                                    <input *ngIf="(this.plant_4!='4'&&this.plant_5!='5')" class="form-control" placeholder="מספר אספקה חדש" type="string"
                                        onkeydown="return event.keyCode !== 69" (focus)="focus2 = true"
                                        (blur)="focus2 = false" [(ngModel)]="newSupplyNum" name="newSupplyNum" />
                                        <input *ngIf="(this.plant_4=='4'&&this.plant_5!='5')" class="form-control" placeholder="מספר אצווה חדש" type="string"
                                        onkeydown="return event.keyCode !== 69" (focus)="focus2 = true"
                                        (blur)="focus2 = false" [(ngModel)]="newSupplyNum" name="newSupplyNum" />
                                        <input *ngIf="(this.plant_4!='4'&&this.plant_5=='5')" class="form-control" placeholder="מספר מכולה חדש" type="string"
                                        onkeydown="return event.keyCode !== 69" (focus)="focus2 = true"
                                        (blur)="focus2 = false" [(ngModel)]="newSupplyNum" name="newSupplyNum" />
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group" [ngClass]="{ focused: focus3 === true }">
                                <div class=" input-group input-group-merge">
                                    <input *ngIf="(this.plant_4!='4'&&this.plant_5!='5')" class="form-control" placeholder="מספר אספקה קיים" type="string"
                                        onkeydown="return event.keyCode !== 69" (focus)="focus3 = true"
                                        (blur)="focus3 = false" [(ngModel)]="currentSupplyNum"
                                        name="currentSupplyNum" />
                                        <input *ngIf="(this.plant_4=='4'&&this.plant_5!='5')" class="form-control" placeholder="מספר אצווה קיים" type="string"
                                        onkeydown="return event.keyCode !== 69" (focus)="focus3 = true"
                                        (blur)="focus3 = false" [(ngModel)]="currentSupplyNum"
                                        name="currentSupplyNum" />
                                        <input *ngIf="(this.plant_4!='4'&&this.plant_5=='5')" class="form-control" placeholder="מספר מכולה קיים" type="string"
                                        onkeydown="return event.keyCode !== 69" (focus)="focus3 = true"
                                        (blur)="focus3 = false" [(ngModel)]="currentSupplyNum"
                                        name="currentSupplyNum" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="(this.plant_4!='4'&&this.plant_5!='5')"  class="modal-footer">
                        <button type="button" class="btn btn-default" ngClass="popup-button"
                            (click)="close()">סגירה</button>
                        <button type="button" class="btn btn-default" ngClass="popup-button"
                            (click)="transferenceFunc()">
                            אישור</button>
                    </div>
                    <div  *ngIf="(this.plant_4=='4'&&this.plant_5!='5')"   class="modal-footer">
                        <button type="button" class="btn btn-default" ngClass="popup-button"
                            (click)="close()">סגירה</button>
                        <button type="button" class="btn btn-default" ngClass="popup-button"
                            (click)="transferenceFunc()">
                            אישור</button>
                    </div>
                    <div *ngIf="(this.plant_4!='4'&&this.plant_5=='5')"  class="modal-footer">
                        <button type="button" class="btn btn-default" ngClass="popup-button"
                            (click)="close()">סגירה</button>
                        <button type="button" class="btn btn-default" ngClass="popup-button"
                            (click)="transferenceFuncRotem()">
                            אישור</button>
                    </div>
                </form>
            </div>
        </div>
    </div>