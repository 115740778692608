<div class="supply">
    <app-header></app-header>
    <div class="container-fluid m-3">
        <div class="row justify-content-center text-center">
            <div>
                <h1 *ngIf="(this.plant_4!='4'&&this.plant_5!='5')" class="display-3">אספקות קיימות עבור יצוא מספר - {{exportNumber}}</h1>
                <h1 *ngIf="(this.plant_4=='4'&&this.plant_5!='5')" class="display-3">אצוות קיימות עבור הזמנה מספר - {{exportNumber}}</h1>
                <h1 *ngIf="(this.plant_4!='4'&&this.plant_5=='5')" class="display-3">מכולות קיימות עבור הזמנה מספר - {{exportNumber}}</h1>
            </div>
        </div>

        <div class="row text-center galleryRow">
            <div class="gallery-container col-md-4 p-4" *ngFor="let sup of listData">
                <img class="img-supply" [src]="getFullPath(sup.path)" (click)="navToSupplyGallery(sup.img_sup_id)" />
                <div class="row m-3 img-bottom">
                    <div class="text-left">
                        <button type="button" class="btn btn-default my-2 p-1" (click)="openFullScreen(sup.path)">
                            <i class="fas fa-expand-arrows-alt" style="margin: 5px 0;"></i>
                        </button>
                        <button type="button" class="btn btn-default my-2 p-1"
                            (click)="addImage(sup.img_sup_id, sup.path)">
                            <span class="glyphicon btn-glyphicon glyphicon-save img-circle material-icons">
                                add_photo_alternate
                            </span>
                        </button>
                    </div>
                    <div class="img-name">
                        <a *ngIf="(this.plant_4!='4'&&this.plant_5=='5')"  class="a-supply" (click)="navToSupplyGallery(sup.img_sup_id)">{{redius_uniq(sup.img_sup_id)}}</a>
                        <a *ngIf="!(this.plant_4!='4'&&this.plant_5=='5')"  class="a-supply" (click)="navToSupplyGallery(sup.img_sup_id)">{{sup.img_sup_id}}</a>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="viewerOpen" class="viewer-container">
            <div class="viewer-content text-center">
                <img class="img-supply" [src]="selectedImgFullScreen" />
                <span class="material-icons close-img" (click)="viewerOpen = false">close</span>
            </div>
        </div>
    </div>
    <app-footer [pageName]="pageName" BackToPrevUrl="/chooseSupply" adminOrOffice="admin" [exportNumber]="exportNumber"
        [btnsPopup]="btnsPopup">
    </app-footer>
    <app-popup-add-image *ngIf="popupAddImgIsOpen" (closePopup)="closePopup()" (postFile)="postFile($event)"
        [pageName]="pageName" [imgPath]="imgPath" [supplyNumber]="supplyNumber" [exportNumber]="exportNumber"
        (confirm)="approveAllExport()">
    </app-popup-add-image>
</div>