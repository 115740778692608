import Apiable, { ApiUserAble} from "./Apiable";
import { User } from "../../Components/UserProfile/User";
import ErrorLogic from "./Errors/ErrorLogic";
import { Session } from "../../Components/UserProfile/Session";
import { CognitoLogin } from "../CognitoLogin";
import { Config } from "../../config/config";
import { CognitoUserProfile } from "../CognitoUserProfile"; 
import ApiGateway from "./ApiGateway"; 
import Network from "../Net/Network";


export default class AwsApi implements Apiable {
    user: ApiUserAble = new AwsApiUserImp();
}


class AwsApiUserImp implements ApiUserAble {

    private cognitoLogin: CognitoLogin
    private UserProfileservice: CognitoUserProfile;

    constructor() {
        this.cognitoLogin = new CognitoLogin({
            UserPoolId: Config.userPoolId,
            ClientId: Config.clientId
        })
        this.UserProfileservice = new CognitoUserProfile({
            UserPoolId: Config.userPoolId,
            ClientId: Config.clientId
        });
    }

    getCurrentUser(): Promise<User | null> {
        return this.UserProfileservice.getCurrentUser();
    }

    updateUserAttributes(updatedUser: User): Promise<User | ErrorLogic> {
        return this.UserProfileservice.updateUserAttributes(updatedUser);
    }

    register(username: string, password: string, email: string, attributes: {[index:string] :string | Date}): Promise<User | ErrorLogic> {
        return this.cognitoLogin.register(username, password, email, attributes);

    }

    deleteCurrentUser(): Promise<boolean> {
        return this.cognitoLogin.deleteCurrentUser();
    }

    confirm(username: string, code: string): Promise<string | boolean> {
        return this.cognitoLogin.confirm(username, code)
    }

    login(email: string, password: string , new_password? : string): Promise<any | Session | ErrorLogic> {
        return this.cognitoLogin.login(email, password , new_password ? new_password : undefined);
    }

    logout(): void {
        return this.cognitoLogin.logout();
    }

    searchUsers(search: string): Promise<User[]> {
        return new Promise((resolve, reject) => {
            let axios = Network.getAxios();

            axios.get('/user/search?query=' + search)
                .then(function (response) {
                    // handle success
                    // console.log(response);
                    var users = [{ firstName: "", lastName: "", linkToprofile: "" }];
                    users = response.data.body;
                    var UsersResult: User[] = [];
                    // users.find( (element ) => {
                    //     if (element.firstName.includes(search) || element.lastName.includes(search)) {
                    //         UsersResult.push(
                    //             new User(element.firstName + element.lastName)
                    //             .setUserLink(element.linkToprofile)
                    //             .setFirstName(element.firstName)
                    //             .setLastName(element.lastName));
                    //     }
                    // });
                    resolve(UsersResult);
                })
                .catch(function (error) {
                    // handle error
                    // console.log(error);
                });
        })
    }

    changePassword(old_password : string , new_password : string , username : string) : Promise <boolean | ErrorLogic>{
        return this.cognitoLogin.changePassword(old_password,new_password,username);
    }

    sendMfaCode(username: string, code: string, session: string) {
        return this.cognitoLogin.sendMfaCode(username, code, session);
    }
}