import { Injectable } from '@angular/core';
import { Session } from 'src/cognito-login/src/Components/UserProfile/Session';
import ApiProvider from 'src/cognito-login/src/Services/Api/ApiProvider';
import { User } from '../classes/User';
import { BehaviorSubject } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AwsAuthService {
  // tslint:disable-next-line: variable-name
  public cognito_aws = ApiProvider;
  // tslint:disable-next-line: variable-name
  _user: User;
  user = new BehaviorSubject<User>(null);
  private tokenExpirationTimer: any;


  constructor(private localstorageService: LocalStorageService, private router: Router) { }

  // tslint:disable-next-line: typedef
  signIn(name: string, password: string, success?: Function, failure?: Function) {
    this.cognito_aws.api.user.login(name, password)
      .then(res => {
        if (res instanceof Session) {
          const session_data = res.getIdToken().split('.');
          const userSession = this.decodeBase64(session_data[1]);

          this.handleAuthentication(
            userSession.name,
            res.getIdToken(),
            userSession.exp
          );
          success(res);
        } else {
          failure(res);
        }
      }).catch((err) => {

        failure(err);
      });
  }

  decodeBase64(text: any) {
    const decodedText = decodeURIComponent(escape(atob(text)));
    const loginDetails = JSON.parse(decodedText);
    return loginDetails;
  }

  private handleAuthentication(name: string, token: string, exp: number) {
    const expirationDate = new Date(exp * 1000).getTime();
    const user = new User(name, token, new Date(expirationDate));
    this.autoLogOut(user.getTokenExpirationMilli() - new Date().getTime());
    this.localstorageService.setItem('loginUser', JSON.stringify(user));
  }

  autoLogOut(expirationDuration: any) {
    const halfHour = 30 * 60 * 1000;
    if (expirationDuration > halfHour) { expirationDuration -= halfHour; }
    expirationDuration = parseInt(expirationDuration); // Convert input to integer
    this.tokenExpirationTimer = setTimeout(() => {
      this.logOut();
    }, expirationDuration);
  }

  logOut() {
    this.user.next(null);
    this.router.navigate(['/sign-in']);
    this._user = null;
    // this.localstorageService.removeItem('userData');
    this.localstorageService.clear();
    if (this.tokenExpirationTimer) {
      clearTimeout(this.tokenExpirationTimer);
    }
    this.tokenExpirationTimer = null;
  }

}
