import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {AdalLoginService} from '../services/adal-login.service'
import {config} from './noneSsoConfig'

@Injectable({
  providedIn: 'root'
})
export class HttpHandlerService {
  private header
  private baseUri = environment.apiUrl;
  private perfix= 'office-sharing/';
  // tslint:disable-next-line: typedef

  buildHeaders(url) {
    
    if(config.url_without_sso.find(element => element === url)){
       this.header = { 'x-api-key': environment.x_api_key, responseType: 'text',
    Accept: 'plain/text' };
    }
    else
    {
    this.header = { responseType: 'text',
    authorization: "Bearer " + this.adalLoginService.getTokenFromLocalStorage(),
    Accept: 'plain/text' };
    
    }
    return this.header;
  }
  constructor(private http: HttpClient,private adalLoginService:AdalLoginService)  { }

  get<T>(url, data?): Observable<T> {

    const options = { headers: this.buildHeaders(url) };
    if (typeof data !== 'undefined') {
      // tslint:disable-next-line: no-string-literal
      options['params'] = data;
    }
   
    if(config.url_without_sso.find(element => element === url)){
      
      return this.http.get<T>(this.baseUri +this.perfix+ url, options);
    }
    else
    return this.http.get<T>(this.baseUri + url, options);
  }

  post<T>(url, data): Observable<T> {
   
    if(config.url_without_sso.find(element => element === url)){
      
      return this.http.post<T>(this.baseUri +this.perfix+ url, data, { headers: this.buildHeaders(url) });
    }
    else
    return this.http.post<T>(this.baseUri + url, data, { headers: this.buildHeaders(url) });
    // return this.http.post<T>(this.baseUri + url, data);
  }

  put<T>(url, data): Observable<T> {
    if(config.url_without_sso.find(element => element === url)){
      return this.http.put<T>(this.baseUri +this.perfix+ url, data);
    }
    else
    return this.http.put<T>(this.baseUri + url, data);
  }
  delete<T>(url): Observable<T> {
    if(config.url_without_sso.find(element => element === url)){
      return this.http.delete<T>(this.baseUri +this.perfix+ url);
    }
    else
    return this.http.delete<T>(this.baseUri + url);
  }
  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    }
    else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      switch (error.status) {
        case 403: {
          console.log("403 Unauthorized");
          this.adalLoginService.logOut();
          this.adalLoginService.login();
          break;
        }
        default: {
          console.log("move to error");
          this.adalLoginService.logOut();
          this.adalLoginService.login();
          break;
        }
      }
    }
    console.log(errorMessage)
    return throwError(errorMessage);
  }

}
