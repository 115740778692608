import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ExportService } from 'src/app/services/export.service';
import { Ng2ImgMaxService } from 'ng2-img-max';
// import { NgxImageCompressService, UploadResponse } from 'ngx-image-compress';

@Component({
  selector: 'app-popup-add-image',
  templateUrl: './popup-add-image.component.html',
  styleUrls: ['./popup-add-image.component.scss']
})
export class PopupAddImageComponent implements OnInit {
  @Output() closePopup = new EventEmitter<any>();
  @Output() postFile = new EventEmitter<any>();
  @Input() pageName: string;
  @Input() supplyNumber: string;
  @Input() exportNumber: string;
  @Input() fileName: string;
  @Input() imgPath: string;
  images = [];
  files_:any = [];
  newResizeImgList:File[] = [];
  newImgName: string;
  resizeProcess: string = 'none';
  fileData: File = null;
  totalFilesLength:number =0;
  plant_4;
  plant_5;
  msg = '';
  url;
  uploadErrorMsg = false;

  constructor(private exportsService: ExportService, private ng2ImgMax: Ng2ImgMaxService) { }

  ngOnInit(): void {
    if(localStorage.getItem('plant_4')=='4')
    this.plant_4='4';
    else
    this.plant_4='0';
    console.log(this.plant_4);
    if(localStorage.getItem('plant_5')=='5')
    this.plant_5='5';
    else
    this.plant_5='0';
    console.log(this.plant_5);
  }

  // tslint:disable-next-line: typedef
  close() {
    this.closePopup.emit();
  }

  // tslint:disable-next-line: typedef
  popupClicked() {
    // tslint:disable-next-line: deprecation
    event.stopPropagation();
  }

  // tslint:disable-next-line: typedef
  selectFileUpdateSupply(event) {
    const mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      this.msg = 'קובץ זה אינו נתמך';
      this.url = null;
      return;
    }

    const reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]);

    // tslint:disable-next-line: variable-name
    reader.onload = (_event) => {
      this.msg = '';
      this.url = reader.result;
    };
    this.uploadErrorMsg = false;
  }

  // tslint:disable-next-line: typedef
  selectFileAddToSupply(event) {
    const mimeType = event.target.files[0].type;
    this.resizeProcess = 'on';
    if (mimeType.match(/image\/*/) == null) {
      this.msg = 'קובץ זה אינו נתמך';
      this.url = null;
      return;
    }

    const reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]);

    this.ng2ImgMax.resize([event.target.files[0]],700,3000).subscribe(result=> {
      console.log(result);
      this.fileData = new File([result,result], result.name);
      this.resizeProcess = 'none';
    },
    error=> {
      console.log('error:',error);
      this.uploadErrorMsg = true;
    })

    const fileType = this.fileData.name.split('.');
    // tslint:disable-next-line: max-line-length
    this.newImgName = this.supplyNumber + '-' + this.exportNumber + '-' + this.randomNumber(0, 9999).toString() + '.' + fileType[fileType.length - 1];
    // tslint:disable-next-line: variable-name
    reader.onload = (_event) => {
      this.msg = '';
      this.url = reader.result;
    };
    this.uploadErrorMsg = false;
  }

  // tslint:disable-next-line: typedef
  uploadImage(x) {
    /*if (this.url === undefined || this.url === null) {
      this.uploadErrorMsg = true;
    }*/
    /*let formData = new FormData();
    formData.append('file', this.fileToUpload, this.fileToUpload.name);
    formData.append('userId', this.userId);
    this.httpClient.post(this.SERVER_URL, formData).subscribe((res) => {

    this.avatarURL = res['avatarURL'];
    });*/
    const formData = new FormData();
    formData.append('image', this.fileData, this.newImgName);
    formData.append('supId', this.supplyNumber);
    if (x === 'replace') {
      formData.append('replaceImgPath', this.imgPath);
    }
    this.closePopup.emit();
    this.postFile.emit(formData);
  }
  // tslint:disable-next-line: typedef
  randomNumber(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  onFileChange(event) {
    this.resizeProcess = 'on';
    if (event.target.files && event.target.files[0]) {

    let all =Array.from(event.target.files);
     this.ng2ImgMax.resize(all as File[],700,3000).subscribe(result=> {
      console.log(result);
      this.newResizeImgList.push(new File([result,result], result.name)); //new File(result,result.name);

      if(this.files_.length === this.newResizeImgList.length){
        this.resizeProcess = 'none';
      }
    },
    error=> {
      console.log('error:',error);
      this.uploadErrorMsg = true;
    })
     
        var filesAmount = event.target.files.length;
        console.log(filesAmount);
        console.log(event.target.files);
        this.totalFilesLength += filesAmount;
        this.files_ = event.target.files;
        for (let i = 0; i < filesAmount; i++) {
          var reader = new FileReader();
  
          reader.onload = (event:any) => {
            console.log(event.target.result);
             this.images.push(event.target.result); 
          }
          reader.readAsDataURL(event.target.files[i]);
        }
    }
  }
  uploadImages(){
    console.log("uploadImages")
    var filesAmount = this.newResizeImgList.length;
    if(this.totalFilesLength === this.newResizeImgList.length){
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.readAsDataURL(this.newResizeImgList[i]);
        let fileData = (this.newResizeImgList[i] as File);
  
        let fileType = fileData.name.split('.');
        let newImgName = this.supplyNumber + '-' + this.exportNumber + '-' + this.randomNumber(0, 9999).toString() + '.' + fileType[fileType.length - 1];
        const formData = new FormData();
        formData.append('image', fileData, newImgName);
        formData.append('supId', this.supplyNumber);
        this.postFile.emit(formData);
      }
             
      this.closePopup.emit();
    }
  }
}

