import { DOCUMENT } from '@angular/common';
import { Component, OnInit, ApplicationRef, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ExportService } from 'src/app/services/export.service';
import { getAnalytics, logEvent } from "firebase/analytics";
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-choose-supply-office',
  templateUrl: './choose-supply-office.component.html',
  styleUrls: ['./choose-supply-office.component.scss']
})
export class ChooseSupplyOfficeComponent implements OnInit {
  private httpUrl = environment.httpUrl;
  viewerOpen = false;
  selectedImgFullScreen;
  popupAddImgIsOpen = false;
  pageName = 'choose-supply-office';
  exportNumber: number;
  listData: any;
  allSupIds = [];
  allSupImages = [];
  uniqueGalleryKey: string;
  private plant_id = null;
   export_name;
   supply_name;
   plant_4='0';
   plant_5='0';

  // tslint:disable-next-line: max-line-length
  constructor(private ref: ApplicationRef, private router: Router, private exportsService: ExportService, private route: ActivatedRoute, @Inject(DOCUMENT) private document: Document) {
    this.plant_id = localStorage.getItem('plant_id');
    console.log(this.plant_id);
    if (this.plant_id==3) {
     this.export_name="Shipment No";
     this.supply_name="Delivery";
    }
    if (this.plant_id==4) {
      this.export_name="הזמנה מספר";
      this.supply_name="אצווה";
      this.plant_4='4';

     }
     if (this.plant_id==5) {
      this.export_name="Order Number ";
      this.supply_name="Containers";
      this.plant_5='5';

     }
     if (this.plant_id==1 || this.plant_id==2) {
    this.supply_name="Supply";
    this.export_name="Export Num";
     }
  }

  // tslint:disable-next-line: typedef
  ngOnInit() {
    
    // this.listData = this.exportsService.getSupply();
    // tslint:disable-next-line: deprecation
    this.route.queryParams.subscribe((params) => {
      const exportNumber = params.exportNumber ? params.exportNumber : localStorage.getItem('exportNumber');
      localStorage.setItem('exportNumber', exportNumber);
      this.exportNumber = exportNumber;
      const dataGetSup = {
        exportNumber,
        status: 1
      };
      // tslint:disable-next-line: deprecation
      this.exportsService.getSupplyHttpRequest(dataGetSup).subscribe((data) => {
        this.allSupIds = data.map(x => x.img_sup_id);
        this.allSupImages = data.map(x => x.path);
        this.listData = data;
      });
    });
  }

  // tslint:disable-next-line: typedef
  navToSupplyGallery(supply) {
    // tslint:disable-next-line: max-line-length
    this.router.navigate(['sendChoice'], { queryParams: { exportNumber: this.exportNumber, supplyId: supply, allSupplyIds: this.allSupIds, allSupplyImages: this.allSupImages } });
  }

  // tslint:disable-next-line: typedef
  openFullScreen(img) {
    this.viewerOpen = true;
    this.selectedImgFullScreen = img;
    this.ref.tick();
  }

  // tslint:disable-next-line: typedef
  viewerClicked() {
    // tslint:disable-next-line: deprecation
    event.stopPropagation();
  }

  // tslint:disable-next-line: typedef
  addImage() {
    this.popupAddImgIsOpen = true;
  }

  // tslint:disable-next-line: typedef
  closePopup() {
    this.popupAddImgIsOpen = false;
  }
  // tslint:disable-next-line: typedef
  sendEntireExport() {
    if (this.allSupIds.length > 0) {
      this.creatNewGallery();
    }
  }


  // tslint:disable-next-line: typedef
  creatNewGallery() {
    this.uniqueGalleryKey = this.numberToCharter(new Date().valueOf());
    const analytics = getAnalytics();
    logEvent(analytics, 'send_part_of_export', {});
    this.openMail();
    // tslint:disable-next-line: deprecation
    this.exportsService.creatGallery(this.uniqueGalleryKey, this.exportNumber, '', this.arrayToString(this.allSupIds), this.arrayToString(this.allSupImages), 'allExport').subscribe(() => {

    });
  }
  numberToCharter(key): string {
    const charters = ['a', 'B', 'c', 'D', 'e', 'F', 'z', 'S', 'v', 'x'];
    const arr = [];
    const temp = key.toString().split('');
    temp.forEach(element => {
      arr.push(charters[Number(element)]);
    });
    return arr.join('');
  }
  openMail(): void {
    const link = this.document.createElement('a');
    link.target = '_blank';
    const header = 'ICL CPFC – REPORT FOR EXPORT NUMBER – ' + this.exportNumber;
    const newLine = '%0D%0A%0D%0A';
    const url = 'mailGallerySupply';
    const sup = 'numbers ' + this.allSupIds;
    // tslint:disable-next-line: max-line-length
    link.href = 'mailto:?subject=' + header + '&body=Hello,%20You%20can%20view%20photos%20taken%20for' + newLine + 'export number ' + this.exportNumber + ',%20And supply ' + sup + newLine + 'In order to view the pictures and download them if necessary, click on the attached link' + newLine + this.httpUrl + url + '?gid=' + this.uniqueGalleryKey + newLine + 'thank you.';
    link.click();
    link.remove();
  }

  // tslint:disable-next-line: typedef
  arrayToString(array) {
    let stringToReturn = '';
    array.forEach(element => {
      stringToReturn += element + ',';
    });

    stringToReturn = stringToReturn.slice(0, -1);
    return stringToReturn;
  }
}

