import { Component, OnInit, ApplicationRef, Renderer2, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExportService } from 'src/app/services/export.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { ImageMagnifierComponent } from '../../components/image-magnifier/image-magnifier.component';

@Component({
  selector: 'app-supply-gallery',
  templateUrl: './supply-gallery.component.html',
  styleUrls: ['./supply-gallery.component.scss']
})
export class SupplyGalleryComponent implements OnInit {
  private httpUrl = environment.httpUrl;
  viewerOpen = false;
  selectedImgFullScreen;
  pageName = 'supply-gallery';
  popupAddImgIsOpen = false;
  public popupApproveIsOpen = false;
  public textPopup = '?האם אתה בטוח שברצונך למחוק את התמונה';
  supplyNumber: number;
  exportNumber: number;
  listData: any;
  deleteAction = false;
  img: any;
  plant_4='0';
  newImgName: string;
  loading: boolean = false;
  plant_5='0';

  constructor(private ref: ApplicationRef, private exportsService: ExportService, private route: ActivatedRoute, private toastr: ToastrService) { }

  ngOnInit(): void {
    if(localStorage.getItem('plant_4')=='4')
    this.plant_4='4';
    else
    this.plant_4='0';
    console.log(this.plant_5);
    if(localStorage.getItem('plant_5')=='5')
    this.plant_5='5';
    else
    this.plant_5='0';
    console.log(this.plant_5);
    this.getSupplyImages();
  }

  // tslint:disable-next-line: typedef
  getSupplyImages() {
    // tslint:disable-next-line: deprecation
    this.route.queryParams.subscribe((params) => {
      this.supplyNumber = params.supplyId;
      this.exportNumber = params.exportId;
      this.exportsService.getSupplyImages(params.supplyId);
      // tslint:disable-next-line: deprecation
      this.exportsService.projectSource$.subscribe((data) => {
        this.listData = data;
      });
    });
  }

  // tslint:disable-next-line: typedef
  postFile(formData) {
    this.loading = true;
    this.exportsService.postFile(formData)
      // tslint:disable-next-line: deprecation
      .subscribe(res => {
        this.getSupplyImages();
        this.loading = false;
        this.toastr.success(null, 'התמונה הועלתה בהצלחה!', {
          progressBar: true,
          progressAnimation: "decreasing",
          closeButton: true,
          extendedTimeOut: 500,
          positionClass: 'toast-top-center',
        });
      });
  }

  // tslint:disable-next-line: typedef
  getImgName(x) {
    let temp = x.split('.')[0].split('-');
    temp = temp[temp.length - 1];
    return temp;
  }

  // tslint:disable-next-line: typedef
  openFullScreen(img) {
    this.viewerOpen = true;
    this.selectedImgFullScreen = img;
    this.ref.tick();
  }


  // tslint:disable-next-line: typedef
  deleteImage(img) {
    this.popupApproveIsOpen = true;
    this.deleteAction = true;
    this.img = img;
  }


  // tslint:disable-next-line: typedef
  deleteImg() {
    // tslint:disable-next-line: deprecation
    this.exportsService.deleteImgHttpRequest(this.img).subscribe((data) => {
      this.getSupplyImages();
    });
  }

  // tslint:disable-next-line: typedef
  addImage() {
    this.popupAddImgIsOpen = true;
  }

  // tslint:disable-next-line: typedef
  closePopup() {
    this.popupAddImgIsOpen = false;
    this.popupApproveIsOpen = false;
  }

  // tslint:disable-next-line: typedef
  approvePartExport() {
    this.popupApproveIsOpen = true;
  }

  // tslint:disable-next-line: typedef
  rotateImage(id, srcUrl) {
    this.listData = null;
    this.loading = true;
    console.log("rotate")
    const imgStyle = document.getElementById(id);
    imgStyle.style.transform = 'rotate(180deg)translate(0%,0%)';

    var img = new Image();
    // img.crossOrigin = 'anonymous';
    img.crossOrigin = '*';
    img.src = this.httpUrl + srcUrl;
    // img.src = 'http://localhost:3000/' + srcUrl;

    // Create a canvas object.
    const canvas = document.createElement('canvas');

    // Wait till the image is loaded.
    img.onload = () => {

      rotateImage();
      let fileType: any = img.src.split('.');
      fileType = fileType[fileType.length - 1];
      console.log(fileType);
      // tslint:disable-next-line: max-line-length
      this.newImgName = this.supplyNumber + '-' + this.exportNumber + '-' + this.randomNumber(0, 9999).toString() + '.' + fileType;
      console.log(this.newImgName)

      saveImage();
    };

    const rotateImage = () => {

      // Create canvas context.
      const ctx = canvas.getContext('2d');

      // Assign width and height.
      canvas.width = img.width;
      canvas.height = img.height;

      ctx.translate(canvas.width / 2, canvas.height / 2);

      // Rotate the image and draw it on the canvas.
      // (I am not showing the canvas on the webpage.
      ctx.rotate(Math.PI);
      ctx.drawImage(img, -img.width / 2, -img.height / 2);
    };

    const saveImage = () => {

      const a = document.createElement('a');
      a.href = canvas.toDataURL('image/png');
      const file = this.DataURIToBlob(canvas.toDataURL('image/png'));
      const formData = new FormData();
      formData.append('image', file, this.newImgName);
      formData.append('supId', this.supplyNumber.toString());
      formData.append('replaceImgRotatePath', srcUrl);
      formData.append('isRotate', 'true');
      console.log("file", file);
      console.log("this.newImgName", this.newImgName);
      console.log("this.supplyNumber.toString()", this.supplyNumber.toString());
      console.log("srcUrl", srcUrl);
      this.exportsService.postFile(formData)
        // tslint:disable-next-line: deprecation
        .subscribe(res => {
          setTimeout(() => {
            this.getSupplyImages();
            this.loading = false;
            this.toastr.success(null, 'התמונה הועלתה בהצלחה!', {
              progressBar: true,
              progressAnimation: "decreasing",
              closeButton: true,
              extendedTimeOut: 500,
              positionClass: 'toast-top-center',
            });
          }, 2000);
        });
      /*a.download = imgName;
      document.body.appendChild(a);
      a.click();*/
    };
    // rotateImage();
    // saveImage(img.src.replace(/^.*[\\\/]/, ''));
  }
  // tslint:disable-next-line: typedef
  DataURIToBlob(dataURI: string) {
    const splitDataURI = dataURI.split(',');
    const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  }
  // tslint:disable-next-line: typedef
  getFullPath(x) {
    // this.exportsService.getSupplyImages(x);

    return this.httpUrl + x;
    // return 'http://localhost:3000/' + x;
  }

  // tslint:disable-next-line: typedef
  randomNumber(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
}
