// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { config } from '../../config';

export const environment = {
  production: false,

  httpUrl: 'https://cpfc-ip.icldig.icl-group.com/',
  x_api_key: 'tEQhm4BeY04XWRUHvpiyEhEtbe0I2Or4A5mZRd0c',
  // local to use with dev dateils in env docmument
  // httpUrl: 'http://localhost:3000/',
  // apiUrl: 'http://localhost:3000/',

  apiUrl: 'https://cpfc.server.private.icldig.icl-group.com/',


firebaseConfig:{
  1 :{ //cpfc-neothovav
      apiKey: "AIzaSyDcrvRWPvG55kYlOqgm4tACEogMU9JN1EI",
      authDomain: "cpfc-neothovav-15a5e.firebaseapp.com",
      projectId: "cpfc-neothovav-15a5e",
      storageBucket: "cpfc-neothovav-15a5e.appspot.com",
      messagingSenderId: "403155106146",
      appId: "1:403155106146:web:404ef4efc556b4520bd43b",
      measurementId: "G-8RJRHCTCCP"
    },
  2 :{ //cpfc-periclass
      apiKey: "AIzaSyA2ZyA2ilPfwosaBMy4wZ___PsHTyiLARU",
      authDomain: "cpfc-periclass.firebaseapp.com",
      projectId: "cpfc-periclass",
      storageBucket: "cpfc-periclass.appspot.com",
      messagingSenderId: "343343917359",
      appId: "1:343343917359:web:2c92f090ac78b974bc19da",
      measurementId: "G-Q4T45PVXLZ"
    },
  3 :{//cpfc-ipt
      apiKey: "AIzaSyBDZ1fhC1VHQgzG4AssNxgYE6zXxkBqkYQ",
      authDomain: "cpfc-ipt.firebaseapp.com",
      projectId: "cpfc-ipt",
      storageBucket: "cpfc-ipt.appspot.com",
      messagingSenderId: "393453439772",
      appId: "1:393453439772:web:b8217acaa937fd7410c8d9",
      measurementId: "G-6MJ8YDRQQZ"
    },
  4 :{//cpfc-sdom
      apiKey: "AIzaSyC2ebBQ3j9ZD-c59LwiDssTnCfSVNHxbPM",
      authDomain: "cpfc-sdom.firebaseapp.com",
      projectId: "cpfc-sdom",
      storageBucket: "cpfc-sdom.appspot.com",
      messagingSenderId: "17506708269",
      appId: "1:17506708269:web:391049c7476c51baf741a4",
      measurementId: "G-G5DC06QXX6"
    },
    5 :{//cpfc-rotem
      apiKey: "AIzaSyC3AD7J5fzHdTocEaSAo7M8-YKNB-8OIKQ",
      authDomain: "cpfc-rotem.firebaseapp.com",
      projectId: "cpfc-rotem",
      storageBucket: "cpfc-rotem.appspot.com",
      messagingSenderId: "323601789937",
      appId: "1:323601789937:web:9bec4cd600b064bc76ca50",
      measurementId: "G-M18BCB8FSX"
    },
  },
  

  adalConfig: {
    tenant: '802762d2-02c4-4677-98ba-54060a234204',
     clientId: 'fa765f32-47f4-452f-bb2f-d030631ff7d0',
    //local
      // redirectUri: 'http://localhost:4200/',
    //dev
    // redirectUri: 'https://cpfc.dev.appsc.icldig.icl-group.com/',
    // clientId:'d77dca7d-980e-4afe-874e-f1859d56ef9a',
    //prod
     redirectUri: 'https://cpfc-ip.icldig.icl-group.com/',
  
    expireOffsetSeconds: 320, //15 minutes (60-15 = 45 minutes auth)
    popUp: false,
    cacheLocation: 'localStorage',
    resource: '36789826-a6c2-4bed-b862-f07d8de6eb34',
  },

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
