
// tslint:disable-next-line: typedef
function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    // tslint:disable-next-line: only-arrow-functions
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}
export class User {
    private tokenpayload;
    constructor(
        public name: string,
        // tslint:disable-next-line: variable-name
        private _token: string,
        public cognitoId?: Date,
    ) {
        if (this._token) {
            this.tokenpayload = parseJwt(this._token);
        }
    }

    // tslint:disable-next-line: typedef
    get token() {
        if (!this.tokenpayload && this._token) {
            this.tokenpayload = parseJwt(this._token);
        }
        if (!this.getTokenExpirationDate() || new Date() > this.getTokenExpirationDate()) {
            return null;
        } else {
            try {
                return this._token;

            } catch (e) {
                return '';
            }

        }
    }
    hasToken(): boolean {
        let ret = false;
        if (!this.tokenpayload && this._token) {
            this.tokenpayload = parseJwt(this._token);
        }
        if (this._token) {
            ret = true;
        }
        return ret;
    }
    // tslint:disable-next-line: typedef
    getTokenExpiration() {
        if (!this.tokenpayload && this._token) {
            this.tokenpayload = parseJwt(this._token);
        }
        if (this.tokenpayload) {
            return this.tokenpayload.exp;
        }
    }
    getTokenExpirationMilli(): number {

        if (!this.tokenpayload && this._token) {
            this.tokenpayload = parseJwt(this._token);
        }
        if (this.tokenpayload) {
            return this.tokenpayload.exp * 1000;
        }
    }
    getTokenExpirationDate(): Date {
        if (!this.tokenpayload && this._token) {
            this.tokenpayload = parseJwt(this._token);
        }
        if (this.tokenpayload) {
            return new Date(this.tokenpayload.exp * 1000);
        }
    }
}

export enum Role {
    admin = 'admin',
    office = 'office',
}
