<app-header [title]="headerText"></app-header>
<div class=" container-fluid mt-2">
    <div class="row">
        <div class="col">
            <div [ngClass]="{'sdom':this.plant_4=='4'}" class="card table-card">
                <div class="row" [hidden]="!dataSource">
                    <mat-form-field *ngIf="this.plant_4!='4'" [ngClass]="{'sdom':this.plant_4=='4','else':!this.plant_4}">
                        <mat-label>Search</mat-label>
                        <input matInput (keyup)="applyFilter($event)" [(ngModel)]="value">
                        <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="clearVal()"
                            class="clear">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-form-field *ngIf="this.plant_4=='4'" [ngClass]="{'sdom':this.plant_4=='4','else':!this.plant_4}">
                        <mat-label>חיפוש</mat-label>
                        <input matInput (keyup)="applyFilter($event)" [(ngModel)]="value">
                        <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="clearVal()"
                            class="clear">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    <div [ngClass]="{'sdom':this.plant_4=='4'}" class="iconLogout">
                        <i [ngClass]="{'sdom':this.plant_4=='4'}" class="fas fa-sign-out-alt fa-lg" (click)="logout()"></i>
                    </div>
                </div>
                <div [hidden]="!dataSource" class="mat-elevation-z8">
                    <table [ngClass]="{'sdom':this.plant_4=='4'}" mat-table [dataSource]="dataSource" matSort class="dir-ltr">
                        
                        

                        <!-- ID Column -->
                        <ng-container *ngIf="this.plant_4!='4'" matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Id </th>
                            <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                        </ng-container>
                        <ng-container *ngIf="this.plant_4=='4'" matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> מזהה </th>
                            <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                        </ng-container>

                        <!-- export_id Column -->
                        <ng-container *ngIf="(this.plant_4!='4'&&this.plant_5!='5')" matColumnDef="export_id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{this.export_name}} </th>
                            <td mat-cell *matCellDef="let row"> {{row.export_id}} </td>
                        </ng-container>
                        <ng-container *ngIf="this.plant_4=='4'" matColumnDef="export_id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> מספר הזמנה </th>
                            <td mat-cell *matCellDef="let row"> {{row.export_id}} </td>
                        </ng-container>
                        <ng-container *ngIf="this.plant_5=='5'" matColumnDef="export_id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Order Number</th>
                            <td mat-cell *matCellDef="let row"> {{row.export_id}} </td>
                        </ng-container>

                        <!-- supplyNums Column -->
                        <ng-container *ngIf="(this.plant_4!='4'&&this.plant_5!='5')" matColumnDef="supply_id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{this.supply_name}} </th>
                            <td mat-cell *matCellDef="let row">
                                <div class="supplyNum">{{row.supply_id}}</div>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="(this.plant_4=='4'&&this.plant_5!='5')" matColumnDef="supply_id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> מספרי אצוות </th>
                            <td mat-cell *matCellDef="let row">
                                <div class="supplyNum">{{row.supply_id}}</div>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="(this.plant_4!='4'&&this.plant_5=='5')" matColumnDef="supply_id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Container numbers</th>
                            <td mat-cell *matCellDef="let row">
                                <div class="supplyNum">{{row.supply_id}}</div>
                            </td>
                        </ng-container>

                        <!-- date Column -->
                        <ng-container *ngIf="this.plant_4!='4'" matColumnDef="due_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Creation Date </th>
                            <td mat-cell *matCellDef="let row" style="direction: ltr;"> {{ row.due_date | date:
                               this.date_storge}} </td>
                                <!-- {{ row.due_date | date:
                                    'dd/MM/yyyy, h:mm a'}} </td -->
                        </ng-container>
                        <ng-container *ngIf="this.plant_4=='4'" matColumnDef="due_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> תאריך יצירה </th>
                            <td mat-cell *matCellDef="let row" style="direction: ltr;"> {{ row.due_date | date:
                               this.date_storge}} </td>
                                <!-- {{ row.due_date | date:
                                    'dd/MM/yyyy, h:mm a'}} </td -->
                        </ng-container>


                        <!-- TYPE Column -->
                        <ng-container *ngIf="this.plant_4!='4'" matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Action </th>
                            <td mat-cell *matCellDef="let row" class="table-actions">
                                <a class="m-2 admin-action" matTooltip="Send by choice"
                                    (click)="approvePartExport(row.export_id)">
                                    <i class="far fa-envelope"></i> </a>

                                <a class="m-2 admin-action" style="color:grey" matTooltip="Send Entire Export"
                                    (click)="sendEntireExport(row.export_id)">
                                    <i class="fas fa-envelope"></i> </a>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="this.plant_4=='4'" matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> פעולה </th>
                            <td mat-cell *matCellDef="let row" class="table-actions">
                                <a class="m-2 admin-action" matTooltip="שלח לפי בחירה"
                                    (click)="approvePartExport(row.export_id)">
                                    <i class="far fa-envelope"></i> </a>

                                <a class="m-2 admin-action" style="color:grey" matTooltip="שלח את ההזמנה כולה"
                                    (click)="sendEntireExport(row.export_id)">
                                    <i class="fas fa-envelope"></i> </a>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4"> no result for "{{value}}"</td>
                        </tr>
                    </table>
                  
                    <mat-paginator [ngClass]="{'sdom':this.plant_4=='4'||this.plant_5=='5'}" [pageSizeOptions]="[ 10, 25,50, 100]"></mat-paginator>
                    <!-- </div> -->

                </div>
                <div [hidden]="dataSource" class="load-data">loading...</div>
            </div>
        </div>
        <app-popup *ngIf="popupTransferenceIsOpen" (closePopup)="closePopup()">
        </app-popup>
        <app-popup-approve *ngIf="popupApproveIsOpen" (closePopup)="closePopup()" [textPopup]="textPopup">
        </app-popup-approve>
    </div>