<div class="header icl-color" dir="rtl">
    <div class=" container-fluid">
        <div class="header-body">
            <div class="row pt-4" ngClass="admin-header">
                <a class="nav-link" href="">
                    <img src="../../../assets/icons/ICL_Logo_white.png" class="navbar-brand-img icon-img-icl"
                        alt="ICL" />
                </a>
                <h1 *ngIf="title" class="mb-0 text-white">{{title}}</h1>
                <img src="../../../assets/icons/LOGO-w.png" class="navbar-brand-img icon-img-app" alt="ICL" />
            </div>
        </div>
    </div>
</div>