<app-header [title]="headerText"></app-header>
<div class=" container-fluid mt-2">
    <div class="row">
        <div class="col">
            <div class="card table-card">
                <div class="row" [hidden]="!dataSource">
                    <mat-form-field>
                        <mat-label>חיפוש</mat-label>
                        <input matInput (keyup)="applyFilter($event)" [(ngModel)]="value">
                        <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="clearVal()"
                            class="clear">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
                    <button class="btn btn-icon btn-default m-5 my-4 text-color" type="button" (click)="transference()">
                        <span class="btn-inner--icon">
                            <i class="fas fa-edit"> </i>
                        </span>
                        לחץ להעברות
                    </button>
                    <div class="iconLogout">
                        <i class="fas fa-sign-out-alt fa-lg" (click)="logout()"></i>
                    </div>
                </div>
                <div [hidden]="!dataSource" class="mat-elevation-z8">
                    <table mat-table [dataSource]="dataSource" matSort matSortActive="due_date" matSortDirection='desc'>

                        <!-- ID Column -->
                        <ng-container matColumnDef="id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> מזהה </th>
                            <td mat-cell *matCellDef="let row"> {{row.id}} </td>
                        </ng-container>

                        <!-- exportNum Column -->
                        <ng-container *ngIf="(this.plant_4!='4'&&this.plant_5!='5')" matColumnDef="export_id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> מספר יצוא </th>
                            <td mat-cell *matCellDef="let row"> {{row.export_id}} </td>
                        </ng-container>
                        <ng-container *ngIf="(this.plant_4=='4'||this.plant_5=='5')" matColumnDef="export_id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> מספר הזמנה </th>
                            <td mat-cell *matCellDef="let row"> {{row.export_id}} </td>
                        </ng-container>

                        <!-- supplyNums Column -->
                        <ng-container *ngIf="(this.plant_4!='4'&&this.plant_5!='5')" matColumnDef="supply_id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> מספרי אספקה </th>
                            <td mat-cell *matCellDef="let row">
                                <div class="supplyNum">{{row.supply_id}}</div>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="(this.plant_4=='4'&&this.plant_5!='5')" matColumnDef="supply_id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> מספרי אצוות </th>
                            <td mat-cell *matCellDef="let row">
                                <div class="supplyNum">{{row.supply_id}}</div>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="(this.plant_4!='4'&&this.plant_5=='5')" matColumnDef="supply_id">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> מספרי מכולות </th>
                            <td mat-cell *matCellDef="let row">
                                <div class="supplyNum">{{row.supply_id}}</div>
                            </td>
                        </ng-container>

                        <!-- date Column -->
                        <ng-container matColumnDef="due_date">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> תאריך יצירה </th>
                            <td mat-cell *matCellDef="let row" dir="ltr"> {{row.due_date| date: 'dd/MM/yyyy, h:mm a'}}
                            </td>
                        </ng-container>

                        <!-- TYPE Column -->
                        <ng-container *ngIf="(this.plant_4!='4'&&this.plant_5!='5')" matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> תחום יצוא </th>
                            <td mat-cell *matCellDef="let row">{{row.type}}</td>
                        </ng-container>
                        <ng-container *ngIf="(this.plant_4=='4'&&this.plant_5!='5')" matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> תחום הזמנה </th>
                            <td mat-cell *matCellDef="let row">{{row.type}}</td>
                        </ng-container>
                        <ng-container *ngIf="(this.plant_4!='4'&&this.plant_5=='5')" matColumnDef="type">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> תחום הזמנה </th>
                            <td mat-cell *matCellDef="let row">{{row.type}}</td>
                        </ng-container>


                        <!-- TYPE Column -->
                        <ng-container *ngIf="(this.plant_4!='4'&&this.plant_5!='5')" matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>פעולה </th>
                            <td mat-cell *matCellDef="let row" class="table-actions">
                                <a class="m-2 admin-action" matTooltip="אשר יצוא שלם"
                                    (click)="approveFullExportPopup(row.export_id)">
                                    <i class="fas fa-check-double"> </i>
                                </a>

                                <a class="m-2 admin-action" style="color:grey" matTooltip="אשר חלק מהיצוא"
                                    (click)="approvePartExport(row.export_id)">
                                    <i class="fas fa-check"> </i>
                                </a>
                            </td>
                        </ng-container>
                        <ng-container *ngIf="(this.plant_4=='4'||this.plant_5=='5')" matColumnDef="action">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>פעולה </th>
                            <td mat-cell *matCellDef="let row" class="table-actions">
                                <a class="m-2 admin-action" style="color:grey" matTooltip="אשר חלק מההזמנה"
                                    (click)="approvePartExport(row.export_id)">
                                    <i class="fas fa-check"> </i>
                                </a>
                                <a class="m-2 admin-action" matTooltip="אשר הזמנה שלמה"
                                    (click)="approveFullExportPopup(row.export_id)">
                                    <i class="fas fa-check-double"> </i>
                                </a>

                                
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                        <!-- Row shown when there is no matching data. -->
                        <tr class="mat-row" *matNoDataRow>
                            <td class="mat-cell" colspan="4"> אין תוצאות עבור "{{value}}"</td>
                        </tr>
                    </table>

                    <mat-paginator [pageSizeOptions]="[ 10, 25,50, 100]"></mat-paginator>
                    <!-- </div> -->
                </div>
                <div [hidden]="dataSource" class="load-data">טוען נתונים...</div>
            </div>
        </div>
        <app-popup *ngIf="popupTransferenceIsOpen" (closePopup)="closePopup()">
        </app-popup>
        <app-popup-approve *ngIf="popupApproveIsOpen" (closePopup)="closePopup()" (confirmFull)="approveAllExport()"
            [textPopup]="textPopup" [btnsPopup]="btnsPopup" [approveFullOrPart]="approveFullOrPart">
        </app-popup-approve>