import { DOCUMENT } from '@angular/common';
import { Component, AfterViewInit, ViewChild, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { ExportService } from 'src/app/services/export.service';
import { environment } from 'src/environments/environment';
import { getAnalytics, logEvent } from "firebase/analytics";
import { FirebaseService } from 'src/app/services/firebase.service';



export interface UserData {
  id: string;
  name: string;
  date: Date;
  exportNum: string;
  supply_id: string[];
  exportType: string;
}

@Component({
  selector: 'app-office',
  templateUrl: './office.component.html',
  styleUrls: ['./office.component.scss']
})

export class OfficeComponent implements AfterViewInit {
  private httpUrl = environment.httpUrl;
  value = '';
  plant_4='0';
  displayedColumns: string[] = ['id', 'export_id', 'supply_id', 'due_date', 'action'];
  dataSource: MatTableDataSource<UserData>;
  public listData;
  public popupTransferenceIsOpen = false;
  public popupApproveIsOpen = false;
  public textPopup = '?האם אתה בטוח שברצונך לבצע יצוא שלם';
  headerText = 'ICL Offices Exports System';
  allSupIds = [];
  allSupImages = [];
  uniqueGalleryKey: string;
  public exportNum;
  private plant_id = null;
  private export_name;
  private supply_name;
  private date_storge;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  plant_5= '0';


  constructor(private router: Router, private exportsService: ExportService, @Inject(DOCUMENT) private document: Document,firebase:FirebaseService) {
    firebase.initFirebase(localStorage.getItem('plant_id'));
    this.plant_id = localStorage.getItem('plant_id');
    if(this.plant_id==4){
    this.textPopup = '?האם אתה בטוח שברצונך לבצע הזמנה שלמה';
    this.plant_4='4';
    console.log(this.plant_4,'this plant-4:')
    // this.headerText='מערכת לשליחת הזמנות';
    this.headerText= "מערכת לשליחת הזמנות"+" - " + this.exportsService.getPlantName()
    }
    if(this.plant_id==5){
      this.plant_5='5';
      console.log(this.plant_5,'this plant-5:')
      }
    if(this.plant_id!=4){
       this.plant_4='0';
      console.log(this.plant_4,'this plant-4:')
      this.headerText = "ICL Offices Exports System"+" - " + this.exportsService.getPlantName()
    }
    (this.plant_id==3) ? this.export_name="Shipment No" :this.export_name="Export Num";
    (this.plant_id==3) ? this.supply_name="Delivery No" :this.supply_name="Supply Numbers";
    (this.plant_id==3) ? this.date_storge='dd-MM-yyyy, HH:mm' :this.date_storge='dd/MM/yyyy, h:mm a';
    this.listData = this.exportsService.getExports(1);
   
    // tslint:disable-next-line: deprecation
    this.exportsService.exportsSource$.subscribe((data) => {
      this.listData = data;

      // Assign the data to the data source for the table to render
      this.dataSource = new MatTableDataSource(this.listData);
      if(localStorage.getItem('plant_5')=='5')
        {
          this.dataSource.filteredData.forEach(element => {
            for(let i=0;i<=element.supply_id.length-1;i++)
            {
              element.supply_id[i]=element.supply_id[i].slice(0, -14).toString()
            
            }
              
            console.log( element.supply_id)
            
          });
          
        }
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  // tslint:disable-next-line: typedef
  ngAfterViewInit() {
    this.plant_id = localStorage.getItem('plant_id');
    if(this.plant_id==4){
    this.plant_4='4';
    console.log(this.plant_4,'this plant-4:')
    }
    else{
      this.plant_4='0';
      // console.log(this.plant_4,'this plant-4:')
    }
    if(this.plant_id==5){
      this.plant_5='5';
      console.log(this.plant_5,'this plant-5:')
      }
      else{
        this.plant_5='0';
        // console.log(this.plant_5,'this plant-4:')
      }
    if (this.plant_4!='4')
    {
    this.paginator._intl.itemsPerPageLabel = 'Number Of Results';
    }
    else{
    this.paginator._intl.itemsPerPageLabel = 'מספר תוצאות לעמוד';
    }
    this.paginator._intl.getRangeLabel = this.getRangeLabel_;
  }
  // tslint:disable-next-line: variable-name
  // tslint:disable-next-line: typedef
  getRangeLabel_ = function (page, pageSize, length) {
    if (length === 0 || pageSize === 0) {
      return '0 od ' + length;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
      this.plant_id = localStorage.getItem('plant_id');
      if(this.plant_id==4){
      this.plant_4='4';
      console.log(this.plant_4,'this plant-4:')
      }
      else{
        this.plant_4='0';
        console.log(this.plant_4,'this plant-4:')
      }
      if(this.plant_id==5){
        this.plant_5='5';
        console.log(this.plant_5,'this plant-5:')
        }
        else{
          this.plant_5='0';
          console.log(this.plant_5,'this plant-5:')
        }
      if (this.plant_4!='4'){
    return 'Showing ' + 1 + ' to ' + endIndex + ' of ' + length; // startIndex + 1 + ' עד ' + endIndex + ' מתוך ' + length;
      }
    else{
    return startIndex + 1 + ' עד ' + endIndex + ' מתוך ' + length
    }
  };
  
 
  // tslint:disable-next-line: typedef
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // tslint:disable-next-line: typedef
  clearVal() {
    this.value = '';
    this.dataSource.filter = this.value;
  }

  // tslint:disable-next-line: typedef
  transference() {
    this.popupTransferenceIsOpen = true;
  }

  // tslint:disable-next-line: typedef
  closePopup() {
    this.popupTransferenceIsOpen = false;
    this.popupApproveIsOpen = false;
  }

  // tslint:disable-next-line: typedef
  sendEntireExport(exportNumber) {
    this.exportNum = exportNumber;
    const dataGetSup = {
      exportNumber: this.exportNum,
      status: 1
    };
    // tslint:disable-next-line: deprecation
    this.exportsService.getSupplyHttpRequest(dataGetSup).subscribe((data) => {
      this.allSupIds = data.map(x => x.img_sup_id);
      this.allSupImages = data.map(x => x.path);
      this.listData = data;
      if (this.allSupIds.length > 0) {
        this.creatNewGallery();
      }
    });
  }

  // tslint:disable-next-line: typedef
  creatNewGallery() {
    this.uniqueGalleryKey = this.numberToCharter(new Date().valueOf());
    const analytics = getAnalytics();
    logEvent(analytics, 'send_entire_export', {});
    this.openMail();
    // tslint:disable-next-line: max-line-length
    // tslint:disable-next-line: deprecation
    this.exportsService.creatGallery(this.uniqueGalleryKey, this.exportNum, '', this.arrayToString(this.allSupIds), this.arrayToString(this.allSupImages), 'allExport').subscribe(() => {

    });
  }

  numberToCharter(key): string {
    const charters = ['a', 'B', 'c', 'D', 'e', 'F', 'z', 'S', 'v', 'x'];
    const arr = [];
    const temp = key.toString().split('');
    temp.forEach(element => {
      arr.push(charters[Number(element)]);
    });
    return arr.join('');
  }

  openMail(): void {
    const link = this.document.createElement('a');
    link.target = '_blank';
    const header = 'ICL CPFC – REPORT FOR EXPORT NUMBER – ' + this.exportNum;
    const newLine = '%0D%0A%0D%0A';
    const url = 'mailGallerySupply';
    const sup = 'numbers ' + this.allSupIds;
    // tslint:disable-next-line: max-line-length
    link.href = 'mailto:?subject=' + header + '&body=Hello,%20You%20can%20view%20photos%20taken%20for' + newLine + 'export number ' + this.exportNum + ',%20And supply ' + sup + newLine + 'In order to view the pictures and download them if necessary, click on the attached link' + newLine + this.httpUrl + url + '?gid=' + this.uniqueGalleryKey + newLine + 'thank you.';
    link.click();
    link.remove();
  }

  // tslint:disable-next-line: typedef
  arrayToString(array) {
    let stringToReturn = '';
    array.forEach(element => {
      stringToReturn += element + ',';
    });

    stringToReturn = stringToReturn.slice(0, -1);
    return stringToReturn;
  }

  // tslint:disable-next-line: typedef
  approvePartExport(x) {
    this.router.navigate(['/chooseSupplyOffice'], { queryParams: { exportNumber: x } });
  }

  // tslint:disable-next-line: typedef
  logout() {
    localStorage.removeItem('loginUser');
    this.router.navigateByUrl('/login');
  }
}




