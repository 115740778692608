import { ExportService } from '../../services/export.service';
import { Component, ViewChild, OnInit, ApplicationRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { tick } from '@angular/core/testing';
import { FirebaseService } from 'src/app/services/firebase.service';

export interface ExportData {
  id: string;
  export_id: string;
  supply_id: string[];
  due_date: string;
  type: string;
}

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  value = '';
  plant_4='0';
  plant_5='0';
  private projectSource = new Subject<any>();
  public projectSource$ = this.projectSource.asObservable();
  displayedColumns: string[] = ['id', 'export_id', 'supply_id', 'due_date', 'type', 'action'];
  displayedColumns_rotem: string[] = ['id', 'export_id', 'supply_id','due_date', 'type', 'action'];
  dataSource: MatTableDataSource<ExportData>;
  public popupTransferenceIsOpen = false;
  public popupApproveIsOpen = false;
  public textPopup = '?האם אתה בטוח שברצונך לבצע יצוא שלם';
  headerText = 'מערכת לאישור תיקים';
  public listData;
  public exportNum;
  public btnsPopup = true;
  approveFullOrPart = '';
  exportSubscriber;
  public loading = false;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(private router: Router, private exportsService: ExportService, private ref: ApplicationRef,firebase:FirebaseService) {
    this.headerText += " - " + this.exportsService.getPlantName();
    firebase.initFirebase(localStorage.getItem('plant_id'));
 

  }
  // tslint:disable-next-line: typedef
   ngOnInit() {
    
    if(localStorage.getItem('plant_4')=='4'){
    this.plant_4='4';
    this.textPopup = '?האם אתה בטוח שברצונך לבצע הזמנה שלמה';
    }
    if(localStorage.getItem('plant_5')=='5'){
      this.plant_5='5';
      this.textPopup = '?האם אתה בטוח שברצונך לבצע הזמנה שלמה';
      this.displayedColumns=this.displayedColumns_rotem;
      }
    else
    // this.plant_4='0';
    console.log(this.plant_4);
    
    
     this.getExports();

    setTimeout(() => {
      this.loading = true;
    }, 3000);
  }

  // tslint:disable-next-line: typedef
  getExports() {
    this.exportsService.getExports(0);
    if (this.exportSubscriber) {
      this.exportSubscriber.unsubscribe();
    }
    // tslint:disable-next-line: deprecation
    this.exportSubscriber = this.exportsService.exportsSource$.subscribe((data) => {
      this.listData = data;

      // Assign the data to the data source for the table to render
      this.dataSource = new MatTableDataSource(data);
      if(localStorage.getItem('plant_5')=='5')
      {
        this.dataSource.filteredData.forEach(element => {
          for(let i=0;i<=element.supply_id.length-1;i++)
          {
            element.supply_id[i]=element.supply_id[i].slice(0, -14).toString()
          
          }
            
          console.log( element.supply_id)
          
        });
        this.exportSubscriber.unsubscribe();
      }
      if(localStorage.getItem('plant_4')=='4')
      {
        this.dataSource.filteredData.forEach((element,index) => {
          const uniqueSet = new Set(element.supply_id);
          element.supply_id = Array.from(uniqueSet);
        });
        this.exportSubscriber.unsubscribe();
      }
  
      console.log(this.dataSource);
      this.ref.tick();
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  // tslint:disable-next-line: typedef
  applyFilter(event: Event) {
    // console.log(event)
    // this.value = (event.target as HTMLInputElement).value;
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  // tslint:disable-next-line: typedef
  clearVal() {
    this.value = '';
    this.dataSource.filter = this.value;
  }

  // tslint:disable-next-line: typedef
  transference() {
    this.popupTransferenceIsOpen = true;
  }

  // tslint:disable-next-line: typedef
  closePopup() {
    this.popupTransferenceIsOpen = false;
    this.popupApproveIsOpen = false;
  }

  // tslint:disable-next-line: typedef
  approveFullExportPopup(exportNum) {
    this.exportNum = exportNum;
    this.popupApproveIsOpen = true;
    this.approveFullOrPart = 'full';
  }

  // tslint:disable-next-line: typedef
  approvePartExport(x) {
    this.router.navigate(['/chooseSupply'], { queryParams: { exportNumber: x } });
  }

  // tslint:disable-next-line: typedef
  approveAllExport() {
    // tslint:disable-next-line: deprecation
    this.exportsService.approveAllExport(this.exportNum).subscribe((data) => {
      this.getExports();
    });
  }

  // tslint:disable-next-line: typedef
  logout() {
    localStorage.removeItem('loginUser');
    this.router.navigateByUrl('/login');
  }
}




