<div class="supply">
    <app-header></app-header>
    <div class="container-fluid m-3">
        <div class="row">
            <button type="button" class="btn btn-default text-white m-1 add-img-gallery" (click)="addImage()">
                הוספת תמונה </button>
            <div class="m-auto">
                <h1  *ngIf="(this.plant_4!='4'&&this.plant_5!='5')" class="display-3">עריכת אספקה מספר - {{supplyNumber}}</h1>
                <h1  *ngIf="(this.plant_4=='4'&&this.plant_5!='5')" class="display-3">עריכת אצווה מספר - {{supplyNumber}}</h1>
                <h1  *ngIf="(this.plant_4!='4'&&this.plant_5=='5')" class="display-3">עריכת מכולה מספר - {{supplyNumber.slice(0, -14)}}</h1>
            </div>
        </div>
        <div class="row text-center galleryRow" [hidden]="!listData">
            <div class="gallery-container col-md- p-4" *ngFor="let img of listData ; let i = index">
                <img class="img-supply img-cursor" id={{i}} [src]="getFullPath(img.path)" />
                <div class="img-bottom">
                    <div class="mt-2">
                        <button class="btn icon-btn btn-default" type="button" (click)="openFullScreen(img.path)">
                            <i class="fas fa-expand-arrows-alt" style="margin: 5px 0;"></i>
                        </button>
                        <button class="btn icon-btn btn-default" type="button" (click)="deleteImage(img.path)">
                            <span class="glyphicon btn-glyphicon glyphicon-save img-circle material-icons">
                                delete
                            </span>
                        </button>
                        <button class="btn icon-btn btn-default" type="button" (click)="rotateImage(i,img.path)">
                            <span class="glyphicon btn-glyphicon glyphicon-save img-circle material-icons">
                                crop_rotate
                            </span>
                        </button>
                    </div>
                    <div class="img-name">
                        <a>{{getImgName(img.path)}}</a>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="viewerOpen" class="viewer-container">
            <div class="viewer-content text-center">
                <!-- <img class="img-supply" [src]="getFullPath(selectedImgFullScreen)" /> -->
                <app-image-magnifier [imageUrl]="getFullPath(selectedImgFullScreen)"></app-image-magnifier>
                <span class="material-icons close-img" (click)="viewerOpen = false">close</span>
            </div>
        </div>
    </div>
    <app-footer [pageName]="pageName" BackToPrevUrl="/chooseSupply" adminOrOffice="admin" [supplyNumber]="supplyNumber">
    </app-footer>
    <app-popup-add-image *ngIf="popupAddImgIsOpen" (closePopup)="closePopup()" (postFile)="postFile($event)"
        [pageName]="pageName" [supplyNumber]="supplyNumber" [exportNumber]="exportNumber">
    </app-popup-add-image>
    <app-popup-approve *ngIf="popupApproveIsOpen" (closePopup)="closePopup()" [textPopup]="textPopup"
        (deleteImg)="deleteImg()" [deleteAction]="deleteAction">
    </app-popup-approve>
</div>
<ngx-loading [show]="loading" [config]="{ fullScreenBackdrop: true }"></ngx-loading>