import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-image-magnifier',
  templateUrl: './image-magnifier.component.html',
  styleUrls: ['./image-magnifier.component.scss']
})
export class ImageMagnifierComponent implements AfterViewInit {
 
  @ViewChild('imageElement') imageElement: ElementRef<HTMLImageElement>;
  @ViewChild('magnifierGlass') magnifierGlass: ElementRef<HTMLDivElement>;
  @Input() imageUrl: string;

  ngAfterViewInit(): void {
    if (!this.imageElement || !this.magnifierGlass) {
      console.error('One or both elements are undefined.');
      return;
    }
    this.magnify('myimage', 2);
  }

  magnify(imgID: string, zoom: number) {
    const img = this.imageElement.nativeElement;
    const glass = this.magnifierGlass.nativeElement;
    let w, h, bw;

    glass.classList.add('img-magnifier-glass');
    img.parentElement.insertBefore(glass, img);

    glass.style.backgroundImage = `url('${img.src}')`;
    glass.style.backgroundRepeat = 'no-repeat';
    glass.style.backgroundSize = `${img.width * zoom}px ${img.height * zoom}px`;

    bw = 3;
    w = glass.offsetWidth / 2;
    h = glass.offsetHeight / 2;

    const moveMagnifier = (e: MouseEvent | TouchEvent) => {
      e.preventDefault();

      const pos = getCursorPos(e);
      let x = pos.x;
      let y = pos.y;

      if (x > img.width - (w / zoom)) { x = img.width - (w / zoom); }
      if (x < w / zoom) { x = w / zoom; }
      if (y > img.height - (h / zoom)) { y = img.height - (h / zoom); }
      if (y < h / zoom) { y = h / zoom; }

      glass.style.left = `${x - w}px`;
      glass.style.top = `${y - h}px`;
      glass.style.backgroundPosition = `-${(x * zoom) - w + bw}px -${(y * zoom) - h + bw}px`;
    };

    const getCursorPos = (e: MouseEvent | TouchEvent) => {
      let a, x = 0, y = 0;
      e = e || window.event as MouseEvent | TouchEvent;
      a = img.getBoundingClientRect();
      x = (e instanceof MouseEvent ? e.pageX : e.touches[0].pageX) - a.left;
      y = (e instanceof MouseEvent ? e.pageY : e.touches[0].pageY) - a.top;
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return { x: x, y: y };
    };

    glass.addEventListener('mousemove', moveMagnifier);
    img.addEventListener('mousemove', moveMagnifier);
    glass.addEventListener('touchmove', moveMagnifier);
    img.addEventListener('touchmove', moveMagnifier);
  }
}
