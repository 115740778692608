import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdalService } from 'adal-angular4';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdalLoginService {

  private token :string = null;

  constructor(private adalService : AdalService, private http : HttpClient) {
    this.adalService.init(environment.adalConfig);
    this.adalService.handleWindowCallback();
   }
   login() { // check if ther has an authentication
    if (this.adalService.userInfo.authenticated) { 
      this.getToken();
      return true;
    } else {
      this.adalService.login();
    }
   }

   logOut() {
    this.adalService.logOut();
   }

   isLoggedIn() {
     return this.adalService.userInfo.authenticated;
   }

   async getToken() {
     if(this.adalService.userInfo.authenticated) {
      this.token = await this.adalService.acquireToken(this.adalService.config.loginResource).toPromise();
      this.adalService.userInfo.token = this.token;
     }
   }
   getTokenFromLocalStorage(){
    if(this.adalService.userInfo.authenticated) {
      return localStorage.getItem("adal.idtoken");
    }
    else{
      this.adalService.login();
    }
   }
}