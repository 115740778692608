// const firebaseConfig = {
//   apiKey: 'AIzaSyBEDQiy7UKIaUaq74_bjfWc7AiI93W_GwA',
//   authDomain: 'icl-cpfc-910f0.firebaseapp.com',
//   projectId: 'icl-cpfc-910f0',
//   storageBucket: 'icl-cpfc-910f0.appspot.com',
//   messagingSenderId: '62718340235',
//   appId: '1:62718340235:web:02696196c7e5442f1df88e',
//   measurementId: 'G-ZK6KR5G4K5'
// };

import { Component, OnInit } from '@angular/core';
import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';
// Initialize Firebase


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  
  title = 'cpfc';

  // tslint:disable-next-line: typedef
  ngOnInit() {
    
    // analytics.logEvent('start_cpfc');
  }
}



