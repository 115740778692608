import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { getAnalytics, logEvent } from "firebase/analytics";


@Component({
  selector: 'app-popup-approve',
  templateUrl: './popup-approve.component.html',
  styleUrls: ['./popup-approve.component.scss']
})

export class PopupApproveComponent implements OnInit {
  @Output() closePopup = new EventEmitter<any>();
  @Output() confirmFull = new EventEmitter<any>();
  @Output() confirmPart = new EventEmitter<any>();
  @Output() deleteImg = new EventEmitter<any>();
  @Input() textPopup;
  @Input() approveFullOrPart;
  @Input() deleteAction;
  btnsPopup: boolean;
  plant_4='0';
  plant_5='0';
  confirmText = 'אישור';
  statusApprove = false;

  constructor(private router: Router) {
    this.btnsPopup = true;
  }

  ngOnInit(): void {
    if(localStorage.getItem('plant_4')=='4')
    this.plant_4='4';
    else
    this.plant_4='0';
    console.log(this.plant_4);
    if(localStorage.getItem('plant_5')=='5')
    this.plant_5='5';
    else
    this.plant_5='0';
    console.log(this.plant_5);
  }

  // tslint:disable-next-line: typedef
  close() {
    this.closePopup.emit();
    if (!this.deleteAction) {
      this.router.navigateByUrl('/admin');
    }
  }

  // tslint:disable-next-line: typedef
  closePopupWrap() {
    if (this.statusApprove && !this.deleteAction) {
      this.close();
    }
    else {
      this.closePopup.emit();
    }
  }

  // tslint:disable-next-line: typedef
  justClosePopup() {
    this.closePopup.emit();
  }

  // tslint:disable-next-line: typedef
  popupClicked() {
    // tslint:disable-next-line: deprecation
    event.stopPropagation();
  }

  // tslint:disable-next-line: typedef
  approve() {
   
    this.statusApprove = true;
    this.btnsPopup = false;
    if (this.approveFullOrPart === 'full') {
      if(this.plant_4!='4'&&this.plant_5!='5')
      this.textPopup = 'היצוא אושר בהצלחה';
      else
      this.textPopup = 'ההזמנה אושרה בהצלחה';
      const analytics = getAnalytics();
      logEvent(analytics, 'approve_entire_export', {});
      this.confirmFull.emit();
      setTimeout(() => {
        this.backToAdmin()
      }, 1500);
     
    } else if (this.approveFullOrPart === 'part') {
      if(this.plant_4!='4'&&this.plant_5!='5')
      this.textPopup = 'האספקה אושרה בהצלחה';
     if(this.plant_4=='4'&&this.plant_5!='5')
      this.textPopup = 'האצווה אושרה בהצלחה';
      if(this.plant_4!='4'&&this.plant_5=='5')
      this.textPopup = 'המכולה אושרה בהצלחה';
      const analytics = getAnalytics();
      logEvent(analytics, 'approve_part_of_export', {});
      this.confirmPart.emit();
      setTimeout(() => {
        this.backToAdmin()
      }, 1500);
     
    } else {
      this.textPopup = 'התמונה נמחקה בהצלחה';
      this.deleteImg.emit();
      setTimeout(() => {
        this.backToAdmin()
      }, 1500);
     
    }
  }

  // tslint:disable-next-line: typedef
  backToAdmin() {
    this.closePopup.emit();
    this.router.navigateByUrl('/admin');
  }
}
