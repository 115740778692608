import { Injectable } from '@angular/core';
import { getAnalytics } from 'firebase/analytics';
import { deleteApp, initializeApp } from 'firebase/app';
import { environment } from 'src/environments/environment';
let app = initializeApp({
  apiKey: 'AIzaSyBEDQiy7UKIaUaq74_bj',
  authDomain: 'test-910f0.firebaseapp.com',
  projectId: 'test-910f0',
  storageBucket: 'test-910f0.appspot.com',
  messagingSenderId: '62718340235',
  appId: '1:62718340235:web:02696196c7e5442f1df88e11',
  measurementId: 'G-ZK6KR5G4K5ddd'

});
getAnalytics(app);

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  public app;

  constructor() { }
  initFirebase(plantId){
    var env=environment.firebaseConfig[plantId];
  deleteApp(app).then(function() {
    app = initializeApp(env);
    getAnalytics(app);
  });
}
}
