import { Loginable } from "../Components/Login/Loginable"; ///home/tcarmel/projects/icl/agropuls/agrupuls/agropuls-ui/src/Components/Login/Loginable.ts
import * as AWSCognito from "amazon-cognito-identity-js";
import { User } from "../Components/UserProfile/User";
import { Session } from "../Components/UserProfile/Session";
import ErrorLogic from "./Api/Errors/ErrorLogic";
// import { EmailValidator } from "./EmailValidator";
// import {dateFormat} from "dateformat";

export class UserPoolSettings {
  constructor(public userPoolId: string, public clientId: string) { }
}

/**
 * An AWS Cognito implementation of the Loginable interface
 */
export class CognitoLogin implements Loginable {
  userPool: AWSCognito.CognitoUserPool;

  constructor(settings: AWSCognito.ICognitoUserPoolData) {
    this.userPool = new AWSCognito.CognitoUserPool(settings);
  }

  /**
   * an interface that describes what returns from the AWS API
   * @param code is the error message code
   * @param message is the message that describes why there is an error
   * @param name is the error name (by the AWS)
   */
  private convertToErrorLogic(error: Error | undefined): ErrorLogic {
    if (error) {
      console.error(error);
      return new ErrorLogic(error.name, error.message);
    }
    return new ErrorLogic("undefined", "undefined");
  }

  async register(
    username: string,
    password: string,
    email: string,
    attributes: { [index: string]: string | Date }
  ): Promise<User | ErrorLogic> {
    return new Promise<User | ErrorLogic>((resolve, reject) => {
      let userAttributes: AWSCognito.CognitoUserAttribute[] = [];


      // userAttributes.push(
      //   new AWSCognito.CognitoUserAttribute({
      //     Name: "family_name",
      //     Value: attributes["lname"]
      //   })
      // );
      // userAttributes.push(
      //   new AWSCognito.CognitoUserAttribute({
      //     Name: "given_name",
      //     Value: attributes["firstName"]
      //   })
      // );
      // userAttributes.push(
      //   new AWSCognito.CognitoUserAttribute({
      //     Name: "custom:city",
      //     Value: attributes["city"]
      //   })
      // );
      // userAttributes.push(
      //   new AWSCognito.CognitoUserAttribute({
      //     Name: "birthdate",
      //     Value: dateFormat(attributes["birthdate"],"yyyy-mm-dd")
      //   })
      // );
      // userAttributes.push(
      //   new AWSCognito.CognitoUserAttribute({
      //     Name: "custom:country",
      //     Value: attributes["country"]
      //   })
      // );
      // userAttributes.push(
      //   new AWSCognito.CognitoUserAttribute({
      //     Name: "gender",
      //     Value: attributes["gender"]
      //   })
      // );

      userAttributes.push(
        new AWSCognito.CognitoUserAttribute({
          Name: "custom:firstname",
          Value: attributes["custom:firstname"] as ''
        })
      );

      userAttributes.push(
        new AWSCognito.CognitoUserAttribute({
          Name: "custom:lastname",
          Value: attributes["custom:lastname"] as ''
        })
      );

      this.userPool.signUp(
        email,
        password,
        userAttributes,
        [],
        (err, result) => {
          if (!err && result) {
            let user = new User(username)
              .setEmail(email)
              .setCity((attributes["city"] as string))
              .setLastName(attributes["lname"] as string)
              .setFirstName(attributes["firstName"] as string)
              .setGender(attributes["gender"] as string)
              .setBirthdate(attributes["birthdate"] as Date)
              .setCountry(attributes["country"] as string)
              .setAbout(attributes["about"] as string);

            // set to all attributes from AWS

            resolve(user);
          } else {
            reject(this.convertToErrorLogic(err));
          }
        }
      );
    });
  }

  deleteCurrentUser(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const cognitoUser = this.userPool.getCurrentUser();
      if (cognitoUser) {
        cognitoUser.deleteUser((err, result) => {
          if (!err) {
            resolve(true);
          }
          reject(err);
        });
      } else {
        reject("No logged-in user");
      }
    });
  }

  changePassword(prev_password: string, new_password: string, username: string): Promise<boolean | ErrorLogic> {
    return new Promise((resolve, reject) => {
      const userData = {
        Username: username,
        Pool: this.userPool
      };



      const cognitoUser = new AWSCognito.CognitoUser(userData);
      cognitoUser.changePassword(
        prev_password,
        new_password,
        (err, res) => {
          if (!err) {
            resolve(true);
          }
          reject(this.convertToErrorLogic(err));
        });
    });
  }

  confirm(username: string, code: string): Promise<boolean | string> {
    return new Promise((resolve, reject) => {
      if (username && username.length > 0 && code && code.length > 0) {
        const userData = {
          Username: username,
          Pool: this.userPool
        };
        const cognitoUser = new AWSCognito.CognitoUser(userData);
        cognitoUser.confirmRegistration(code, true, (err, result) => {
          if (!err) {
            resolve(true);
          }
          resolve(false);
        });
      } else {
        reject("Missing required parameters");
      }
    });
  }

  login(username: string, password: string, new_password?: string): Promise<any | Session | ErrorLogic> {
    return new Promise((resolve, reject) => {
      const authenticationDetails = new AWSCognito.AuthenticationDetails({
        Username: username,
        Password: password
      });

      const cognitoUser = new AWSCognito.CognitoUser({
        Username: username,
        Pool: this.userPool
      });
      cognitoUser.setAuthenticationFlowType("USER_PASSWORD_AUTH");

      const self = this;

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (session) {
          resolve(new Session(session.getIdToken().getJwtToken()));
        },
        onFailure: function (err) {
          reject(self.convertToErrorLogic(err));
        },
        mfaRequired: codeDeliveryDetails => {
          resolve(cognitoUser);
        },
        newPasswordRequired: function (userAttributes, requiredAttributes) {
          debugger;
          // if( new_password != ''){

          //   cognitoUser.

          // }
          cognitoUser.completeNewPasswordChallenge(password + "1", [], this);
        }
      });
    });
  }

  sendMfaCode(username: string, code: string, session: string) {
    return new Promise<Session | ErrorLogic>((resolve, reject) => {
      const cognitoUser = new AWSCognito.CognitoUser({
        Username: username,
        Pool: this.userPool
      });
      cognitoUser["Session"] = session;
      const self = this;

      cognitoUser.sendMFACode(code, {
        onSuccess: function (session) {
          resolve(new Session(session.getIdToken().getJwtToken()));
        },
        onFailure: function (err) {
          reject(self.convertToErrorLogic(err));
        }
      });
    });
  }

  logout() {
    const cognitoUser = this.userPool.getCurrentUser();
    if (cognitoUser) {
      cognitoUser.signOut();
    } else {
      console.error("No user is currently logged-in");
    }
  }
}
