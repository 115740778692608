<div class="popup-wrap" (click)="close()">
    <div *ngIf="pageName==='choose-supply'" class="modal-dialog" (click)="popupClicked()">
        <div class="modal-content">
            <div class="modal-header">
                <h2 *ngIf="(this.plant_4!='4'&&this.plant_5!='5')" class="mb-0">ערוך תמונה למספר אספקה - {{supplyNumber}}</h2>
                <h2 *ngIf="(this.plant_4=='4'&&this.plant_5!='5')" class="mb-0">ערוך תמונה למספר אצווה - {{supplyNumber}}</h2>
                <h2 *ngIf="(this.plant_4!='4'&&this.plant_5=='5')" class="mb-0">ערוך תמונה למספר מכולה - {{supplyNumber}}</h2>
            </div>
            <div class="modal-body">
                <h4 class="mb-1">בחר תמונה</h4>
                <div class="text-right" ngClass="uploadFile">
                    <div class="form-group mb-2">
                        <div class="errorMsg" [innerHtml]='msg' *ngIf="msg" style="color: red;"></div>
                        <div><img [src]="url" *ngIf="url" ngClass="imgFormSize"></div>
                        <div>
                            <input type="file" (change)="selectFileAddToSupply($event)" ngClass="inputUploadImg">
                        </div>
                    </div>
                    <div class="btn-upload">
                        <div class="errorMsg" *ngIf="uploadErrorMsg" style="color: red;">אנא בחר תמונה
                        </div>
                        <div class="errorMsg" *ngIf="resizeProcess === 'on'" style="color: red;">אנא המתן מס שניות התמונה בתהליך הקטנה
                        </div>
                        <button type="button" [class.resizing]="resizeProcess === 'on'" class="btn btn-outline-secondary text-white icl-color"
                            (click)="uploadImage('replace')">העלה
                            תמונה</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="pageName==='supply-gallery'" class="modal-dialog" (click)="popupClicked()">
        <div class="modal-content">
            <div class="modal-header">
                <h2 *ngIf="(this.plant_4!='4'||this.plant_5!='5')" class="mb-0">הוסף תמונות חדשות למספר אספקה - {{supplyNumber}}</h2>
                <h2 *ngIf="(this.plant_4=='4'&&this.plant_5!='5')" class="mb-0">הוסף תמונות חדשות למספר אצווה - {{supplyNumber}}</h2>
                <h2 *ngIf="(this.plant_4!='4'&&this.plant_5=='5')" class="mb-0">הוסף תמונות חדשות למספר מכולה - {{supplyNumber}}</h2>

            </div>
            <div class="modal-body">
                <h4 class="mb-1">בחר תמונות</h4>
                <div class="text-right" ngClass="uploadFile">
                    <div class="form-group mb-2">
                        <div class="errorMsg" [innerHtml]='msg' *ngIf="msg" style="color: red;"></div>
                        <div>
                            <img *ngFor='let url of images' [src]="url" ngClass="imgFormSize" style="margin: 3px;width: 100px;"> <br/>
                            <!-- <img [src]="url" *ngIf="url" ngClass="imgFormSize"></div> -->
                        <div>
                            <input type="file" multiple="" id="file" (change)="onFileChange($event)" ngClass="inputUploadImg">  <!--(change)="selectFileAddToSupply($event)"-->
                        </div>
                    </div>
                    <div class="btn-upload">
                        <div class="errorMsg" *ngIf="uploadErrorMsg" style="color: red;">אנא בחר תמונה
                        </div>
                        <div class="errorMsg" *ngIf="resizeProcess === 'on'" style="color: red;">אנא המתן מס שניות התמונות בתהליך הקטנה
                        </div>
                        <button type="button" [class.resizing]="resizeProcess === 'on'" class="btn btn-outline-secondary text-white icl-color" [disable]="resizeProcess === 'on'"
                            (click)="uploadImages()">העלה
                            תמונות</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>