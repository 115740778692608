<div class="supply">
    <app-header></app-header>
    <div class="container-fluid m-3">
        <div class="row justify-content-center text-center">
            <div>
                <h1 class="display-3">All {{this.supply_name}} For {{this.export_name}} - {{exportNumber}}</h1>
            </div>
        </div>

        <div class="row text-center galleryRow">
            <div class="gallery-container col-md-4 p-4" *ngFor="let sup of listData">
                <img class="img-supply" [src]="sup.path" (click)="navToGallery(sup.id)" />
                <div class="img-bottom">
                    <div class="mt-2">
                        <button class="btn icon-btn btn-default" type="button" (click)="openFullScreen(sup.path)">
                            <i class="fas fa-expand-arrows-alt" style="margin: 5px 0;"></i>
                        </button>
                    </div>
                    <div class="img-name">
                        <a class="a-supply" (click)="navToGallery(sup.id)">{{sup.id.slice(0, -14)}}</a>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="viewerOpen" class="viewer-container">
            <div class="viewer-content text-center">
                <img class="img-supply" [src]="selectedImgFullScreen" />
                <span class="material-icons close-img" (click)="viewerOpen = false">close</span>
            </div>
        </div>
    </div>
    <app-popup-add-image *ngIf="popupAddImgIsOpen" (closePopup)="closePopup()" [pageName]="pageName">
    </app-popup-add-image>
</div>