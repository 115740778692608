import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class MatPaginatorIntlCro extends MatPaginatorIntl {
    itemsPerPageLabel = 'מספר תוצאות לעמוד';
    nextPageLabel = 'עמוד הבא';
    previousPageLabel = 'עמוד קודם';

    // tslint:disable-next-line: typedef
    // tslint:disable-next-line: only-arrow-functions
    getRangeLabel = function (page, pageSize, length) {
        if (length === 0 || pageSize === 0) {
            return '0 od ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ?
            Math.min(startIndex + pageSize, length) :
            startIndex + pageSize;
        return startIndex + 1 + ' עד ' + endIndex + ' מתוך ' + length;
    };

}
