import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ExportService } from 'src/app/services/export.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  @Input() pageName: string;
  @Input() BackToPrevUrl: string;
  @Input() adminOrOffice: string;
  @Input() exportNumber: number;
  @Input() supplyNumber: number;
  @Input() btnsPopup: boolean;
  @Output() sendExport = new EventEmitter<string>();
  public popupApproveIsOpen = false;
  plant_4;
  plant_5;
  public textPopup = '';
  approveFullOrPart = '';

  constructor(private router: Router, private exportsService: ExportService) { }

  ngOnInit(): void {
    if(localStorage.getItem('plant_4')=='4')
    this.plant_4='4';
    else
    this.plant_4='0';
    console.log(this.plant_5);
    if(localStorage.getItem('plant_5')=='5')
    this.plant_5='5';
    else
    this.plant_5='0';
    console.log(this.plant_5);
  }

  // tslint:disable-next-line: typedef
  sendEntireExport() {
    this.sendExport.emit();
  }
  // tslint:disable-next-line: typedef
  BackToHome() {
    this.router.navigateByUrl('/admin');
  }

  // tslint:disable-next-line: typedef
  BackToPrevPage() {
    this.router.navigateByUrl(this.BackToPrevUrl);
  }

  // tslint:disable-next-line: typedef
  approveFullExportPopup() {
    if(!this.plant_4)
    this.textPopup = '?האם אתה בטוח שברצונך לבצע יצוא שלם';
    else
    this.textPopup = '?האם אתה בטוח שברצונך לבצע הזמנה שלמה';
    this.popupApproveIsOpen = true;
    this.approveFullOrPart = 'full';
  }

  // tslint:disable-next-line: typedef
  approvePartExportPopup() {
    if(!this.plant_4)
    this.textPopup = '?האם אתה בטוח שברצונך לאשר את האספקה  ';
    if(this.plant_4)
    this.textPopup = '?האם אתה בטוח שברצונך לאשר את האצווה  ';
    if(this.plant_5=='5')
    this.textPopup = '?האם אתה בטוח שברצונך לאשר את המכולה  ';
    this.popupApproveIsOpen = true;
    this.approveFullOrPart = 'part';
  }

  // tslint:disable-next-line: typedef
  closePopup() {
    this.popupApproveIsOpen = false;
  }

  // tslint:disable-next-line: typedef
  approveAllExport() {
    // tslint:disable-next-line: deprecation
    this.exportsService.approveAllExport(this.exportNumber).subscribe(() => {
      // window.location.reload();
      // this.BackToHome();
    });
  }

  // tslint:disable-next-line: typedef
  approvePartExport() {
    // tslint:disable-next-line: deprecation
    this.exportsService.approvePartExport(this.supplyNumber).subscribe(() => {
      // window.location.reload();
      // this.BackToHome();
    });
  }
}
