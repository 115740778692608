<div class="popup-wrap" (click)="closePopupWrap()">
    <div class="modal-dialog" (click)="popupClicked()">
        <div class="modal-content">
            <div class="modal-header">
                <!-- <h5 class="modal-title">Modal title</h5> -->
                <button *ngIf="!btnsPopup" type="button" class="close" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body text-center">
                <h2>{{textPopup}}</h2>
            </div>
            <div>
                <div class="modal-footer">
                    <button *ngIf="btnsPopup" type="button" class="btn btn-default" ngClass="popup-button"
                        (click)="justClosePopup()">סגירה</button>
                    <button *ngIf="btnsPopup" type="button" class="btn btn-default" ngClass="popup-button"
                        (click)="approve()">
                        {{confirmText}} </button>
                    <!-- <button *ngIf="!btnsPopup" type="button" class="btn btn-default" ngClass="popup-button"
                        (click)="backToAdmin()">סגירה </button> -->
                </div>
            </div>
        </div>
    </div>
</div>