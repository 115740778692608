import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportService } from 'src/app/services/export.service';

@Component({
  templateUrl: './mail-gallery-supply.component.html',
  styleUrls: ['./mail-gallery-supply.component.scss']
})
export class MailGallerySupplyComponent implements OnInit {

  viewerOpen = false;
  selectedImgFullScreen;
  popupAddImgIsOpen = false;
  public popupApproveIsOpen = false;
  listData: any;
  exportNumber: number;
  private plant_id = null;
   export_name;
   supply_name;

  constructor(private route: ActivatedRoute, private router: Router, private exportsService: ExportService) { 
    console.log(this.plant_id);
    this.plant_id = localStorage.getItem('plant_id');
    (this.plant_id==3) ? this.export_name="Shipment No" :this.export_name="Export Num";
    (this.plant_id==3) ? this.supply_name="Delivery" :this.supply_name="Supply";
  }

  ngOnInit(): void {
    // tslint:disable-next-line: deprecation
    this.route.queryParams.subscribe((params) => {
      if (params.gid) {
        // tslint:disable-next-line: no-unused-expression
        (params.gid);
        this.exportsService.getGalleryDetails(params.gid);
        // tslint:disable-next-line: deprecation
        this.exportsService.projectSource$.subscribe((data) => {
          const allObject = data[0];
          if (allObject) {
            this.exportNumber = allObject.exportNumber;
            this.listData = [];
            const supplyIds = allObject.supplyNumbers.split(',');
            const supplyImages = allObject.supplyImages.split(',');
            for (let i = 0; i < supplyIds.length; i++) {
              this.listData.push({ id: supplyIds[i], path: supplyImages[i] });
            }
          }
        });
      }
    });
  }
  // tslint:disable-next-line: typedef
  navToGallery(x) {
    this.router.navigate(['mailGallery'], { queryParams: { supplyId: x } });
  }
}
