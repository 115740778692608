<!-- Footer -->
<footer class="page-footer icl-color">

    <!-- Footer Elements -->
    <div class="container m-0">
        <div class="row">
            <div class="modal-footer" *ngIf="adminOrOffice === 'admin'">
                <button *ngIf="pageName==='choose-supply'&& (this.plant_4!='4'&&this.plant_5!='5')" type="button" class="btn  btn-default text-white m-1"
                    (click)="approveFullExportPopup()">
                    אשר יצוא שלם <i class="fas fa-check"> </i></button>
                    <button *ngIf="pageName==='choose-supply'&& (this.plant_4=='4'||this.plant_5=='5')" type="button" class="btn  btn-default text-white m-1"
                    (click)="approveFullExportPopup()">
                אשר הזמנה שלמה <i class="fas fa-check"> </i></button>

                <button  *ngIf="pageName==='supply-gallery' && (this.plant_4!='4'&&this.plant_5!='5')" type="button" class="btn  btn-default text-white m-1"
                    (click)="approvePartExportPopup()">
                    אשר אספקה <i class="fas fa-check"> </i></button>
                    <button  *ngIf="pageName==='supply-gallery' && (this.plant_4=='4'&&this.plant_5!='5')" type="button" class="btn  btn-default text-white m-1"
                    (click)="approvePartExportPopup()">
                    אשר אצווה <i class="fas fa-check"> </i></button>`
                    <button  *ngIf="pageName==='supply-gallery' && (this.plant_4!='4'&&this.plant_5=='5')" type="button" class="btn  btn-default text-white m-1"
                    (click)="approvePartExportPopup()">
                    אשר מכולה <i class="fas fa-check"> </i></button>`

                <button *ngIf="pageName ==='supply-gallery'" type="button" class="btn  btn-default text-white m-1"
                    (click)="BackToPrevPage()">
                    חזרה לעמוד הקודם <i class="fas fa-arrow-right"> </i></button>

                <button type="button" class="btn  btn-default text-white m-1" (click)="BackToHome()">חזרה לעמוד
                    הראשי <i class="fas fa-home"> </i></button>
            </div>
            <div class="modal-footer" *ngIf="adminOrOffice === 'office' && (this.plant_4!='4') ">
                <button type="button" class="btn  btn-default text-white m-1" (click)="BackToPrevPage()">
                    <i class="fas fa-arrow-left"> </i> Return </button>
                <a class="btn  btn-default text-white m-1" (click)="sendEntireExport()">Send Entire Export</a>
            </div>
            <div class="modal-footer" *ngIf="adminOrOffice === 'office' && (this.plant_4=='4')">
                <button type="button" class="btn  btn-default text-white m-1" (click)="BackToPrevPage()">
                    <i class="fas fa-arrow-left"> </i> חזור </button>
                <a class="btn  btn-default text-white m-1" (click)="sendEntireExport()">שלח את כל ההזמנה</a>
            </div>
            
        </div>
    </div>
    <!-- Footer Elements -->
    <app-popup-approve *ngIf="popupApproveIsOpen" (closePopup)="closePopup()" [textPopup]="textPopup"
        (confirmFull)="approveAllExport()" (confirmPart)="approvePartExport()" [approveFullOrPart]="approveFullOrPart">
    </app-popup-approve>
</footer>
<!-- Footer -->