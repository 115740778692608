import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { AdminComponent } from './pages/admin/admin.component';
import { ChooseSupplyComponent } from './pages/choose-supply/choose-supply.component';
import { SupplyGalleryComponent } from './pages/supply-gallery/supply-gallery.component';
import { OfficeComponent } from './pages/office/office.component';
import { ChooseSupplyOfficeComponent } from './pages/choose-supply-office/choose-supply-office.component';
import { sendChoiceComponent } from './pages/send-choice/send-choice.component';
import { MailGalleryComponent } from './pages/mailGallery/mailGallery.component';
import { MailGallerySupplyComponent } from './pages/mail-gallery-supply/mail-gallery-supply.component';

import { AuthGuardService } from './services/guard/auth-guard.service';
import { AdminGuardService } from './services/guard/admin-guard.service';
import { OfficeGuardService } from './services/guard/office-guard.service';
import { MsalGuard } from './guards/msal.guard';

const routes: Routes = [

  { path: '', redirectTo: 'login', pathMatch: 'full'},
  { path: 'login', component: LoginComponent,canActivate: [MsalGuard] },
  { path: 'admin', component: AdminComponent},
  { path: 'chooseSupply', component: ChooseSupplyComponent },
  { path: 'supplyGallery', component: SupplyGalleryComponent },
  { path: 'office', component: OfficeComponent },
  { path: 'chooseSupplyOffice', component: ChooseSupplyOfficeComponent},
  { path: 'sendChoice', component: sendChoiceComponent},
  { path: 'mailGallery', component: MailGalleryComponent },
  { path: 'mailGallerySupply', component: MailGallerySupplyComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
