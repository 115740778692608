import { Component, OnInit, ApplicationRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportService } from 'src/app/services/export.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-choose-supply',
  templateUrl: './choose-supply.component.html',
  styleUrls: ['./choose-supply.component.scss']
})
export class ChooseSupplyComponent implements OnInit {
  private httpUrl = environment.httpUrl;
  viewerOpen = false;
  selectedImgFullScreen;
  popupAddImgIsOpen = false;
  pageName = 'choose-supply';
  exportNumber: number;
  listData: any;
  supplyNumber: string;
  plant_4;
  plant_5;
  imgPath: string;
  public btnsPopup = true;

  constructor(private ref: ApplicationRef, private router: Router, private exportsService: ExportService, private route: ActivatedRoute,private toastr: ToastrService) {
  }

  // tslint:disable-next-line: typedef
  ngOnInit() {
    if(localStorage.getItem('plant_4')=='4')
    this.plant_4='4';
    else
    this.plant_4='0';
    console.log(this.plant_4);
    if(localStorage.getItem('plant_5')=='5')
    this.plant_5='5';
    else
    this.plant_5='0';
    console.log(this.plant_5);
    this.getSupplyImages();
  }

  // tslint:disable-next-line: typedef
  postFile(formData) {

    this.exportsService.postFile(formData)
      // tslint:disable-next-line: deprecation
      .subscribe(res => {
        this.getSupplyImages();
        this.toastr.success(null,'התמונה הועלתה בהצלחה!',{
          progressBar: true,
          progressAnimation:"decreasing",
          closeButton: true,
          extendedTimeOut : 500,
          positionClass: 'toast-top-center',
        });
      });
  }

  // tslint:disable-next-line: typedef
  getSupplyImages() {
    // tslint:disable-next-line: deprecation
    this.route.queryParams.subscribe((params) => {
      const exportNumber = params.exportNumber ? params.exportNumber : localStorage.getItem('exportNumber');
      localStorage.setItem('exportNumber', exportNumber);
      this.exportNumber = exportNumber;
      const dataGetSup = {
        exportNumber,
        status: 0
      };
      // tslint:disable-next-line: deprecation
      this.exportsService.getSupplyHttpRequest(dataGetSup).subscribe((data) => {
        this.listData = data;
      
      });
     
    });
  }

  // tslint:disable-next-line: typedef
  navToSupplyGallery(supply) {
    this.router.navigate(['supplyGallery'], { queryParams: { supplyId: supply, exportId: this.exportNumber } });
  }
  redius_uniq(x){
    return x.slice(0, -14)

  }

  // tslint:disable-next-line: typedef
  openFullScreen(img) {
    this.viewerOpen = true;
    this.selectedImgFullScreen = img;
    this.ref.tick();
  }

  // tslint:disable-next-line: typedef
  viewerClicked() {
    // tslint:disable-next-line: deprecation
    event.stopPropagation();
  }

  // tslint:disable-next-line: typedef
  addImage(x, imgPath) {
    this.supplyNumber = x;
    this.imgPath = imgPath;
    this.popupAddImgIsOpen = true;
  }

  // tslint:disable-next-line: typedef
  closePopup() {
    this.popupAddImgIsOpen = false;
  }

  // tslint:disable-next-line: typedef
  getFullPath(x) {
    // this.exportsService.getSupplyImages(x);
    return this.httpUrl + x;
    // return '' + x;
  }
}
