import { DOCUMENT } from '@angular/common';
import { Component, OnInit, ApplicationRef, Renderer2, ViewEncapsulation, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExportService } from 'src/app/services/export.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-send-choice',
  templateUrl: './send-choice.component.html',
  styleUrls: ['./send-choice.component.scss'],
  encapsulation: ViewEncapsulation.None
})

// tslint:disable-next-line: class-name
export class sendChoiceComponent implements OnInit {
  private httpUrl = environment.httpUrl;
  viewerOpen = false;
  selectedImgFullScreen;
  pageName = 'send-choice';
  popupAddImgIsOpen = false;
  public popupApproveIsOpen = false;
  selectAllText = 'Select All';
  supplyNumber: number;
  allSupplyNumbers: any;
  allSupplyImages: any;
  exportNumber: number;
  uniqueGalleryKey: string;
  plant_id;
  plant_4='0';
  plant_5='0';
  listData: any;

  // tslint:disable-next-line: max-line-length
  constructor(private ref: ApplicationRef, private exportsService: ExportService, private route: ActivatedRoute, @Inject(DOCUMENT) private document: Document) { 
    this.plant_id = localStorage.getItem('plant_id');
    console.log(this.plant_id);
    if(localStorage.getItem('plant_4')=='4')
    this.plant_4='4';
    else
    this.plant_4='0';
    console.log(this.plant_5);
    if(localStorage.getItem('plant_5')=='5')
    this.plant_5='5';
    else
    this.plant_5='0';
    console.log(this.plant_5);
  }

  ngOnInit(): void {

    // tslint:disable-next-line: deprecation
    this.route.queryParams.subscribe((params) => {
      this.supplyNumber = params.supplyId;
      this.allSupplyNumbers = params.allSupplyIds;
      this.allSupplyImages = params.allSupplyImages;
      this.exportNumber = params.exportNumber;
      this.exportsService.getSupplyImages(params.supplyId);
      // tslint:disable-next-line: deprecation
      this.exportsService.projectSource$.subscribe((data) => {
        if (data) {
          data.forEach(element => {
            element.selected = false;
          });
        }
        this.listData = data;
      });
    });
  }

  // tslint:disable-next-line: typedef
  sendEntireExport() {
    this.creatNewGallery('allExport');
  }

  // tslint:disable-next-line: typedef
  onImageSelect(index) {
    this.listData[index].selected = !this.listData[index].selected;
    this.selectAllText = 'Select All';
  }

  // tslint:disable-next-line: typedef
  openFullScreen(img) {
    this.viewerOpen = true;
    this.selectedImgFullScreen = img;
    this.ref.tick();
  }

  // tslint:disable-next-line: typedef
  getImgName(x) {
    let temp = x.split('.')[0].split('-');
    temp = temp[temp.length - 1];
    return temp;
  }

  // tslint:disable-next-line: typedef
  creatNewGallery(x) {
    if (x || this.numSelected() > 0) {
      this.uniqueGalleryKey = this.numberToCharter(new Date().valueOf());

      this.openMail(x);
      // tslint:disable-next-line: no-shadowed-variable
      const imagesPath = x === 'allExport' ? 'allExport' : this.arrayToString(this.listData.filter(y => y.selected).map((x) => x.path));
      if (imagesPath) {
        // tslint:disable-next-line: max-line-length
        // tslint:disable-next-line: deprecation
        this.exportsService.creatGallery(this.uniqueGalleryKey, this.exportNumber, this.supplyNumber, this.arrayToString(this.allSupplyNumbers), this.arrayToString(this.allSupplyImages), imagesPath).subscribe(() => {
        });
      }
    }
  }

  numberToCharter(key): string {
    const charters = ['a', 'B', 'c', 'D', 'e', 'F', 'z', 'S', 'v', 'x'];
    const arr = [];
    const temp = key.toString().split('');
    temp.forEach(element => {
      arr.push(charters[Number(element)]);
    });
    return arr.join('');
  }

  openMail(x): void {
    const link = this.document.createElement('a');
    link.target = '_blank';
    const header = 'ICL CPFC – REPORT FOR EXPORT NUMBER – ' + this.exportNumber;
    const newLine = '%0D%0A%0D%0A';
    const url = x === 'allExport' ? 'mailGallerySupply' : 'mailGallery';
    const sup = x === 'allExport' ? 'numbers ' + this.allSupplyNumbers : 'number ' + this.supplyNumber;
    // tslint:disable-next-line: max-line-length
    link.href = 'mailto:?subject=' + header + '&body=Hello,%20You%20can%20view%20photos%20taken%20for' + newLine + 'export number ' + this.exportNumber + ',%20And supply ' + sup + newLine + 'In order to view the pictures and download them if necessary, click on the attached link' + newLine + this.httpUrl + url + '?gid=' + this.uniqueGalleryKey + newLine + 'thank you.';
    link.click();
    link.remove();
  }

  // tslint:disable-next-line: typedef
  arrayToString(array) {
    let stringToReturn = '';
    array.forEach(element => {
      stringToReturn += element + ',';
    });

    stringToReturn = stringToReturn.slice(0, -1);
    return stringToReturn;
  }

  // tslint:disable-next-line: typedef
  selectAllImg() {
    if (this.selectAllText === 'Unselect All') {
      this.selectAllText = 'Select All';
      this.listData.forEach(x => x.selected = false);
    } else {
      this.selectAllText = 'Unselect All';
      this.listData.forEach(x => x.selected = true);
    }
  }

  // tslint:disable-next-line: typedef
  numSelected() {
    if (this.listData) {
      return this.listData.filter(x => x.selected).length;
    }
    return 0;
  }

  // tslint:disable-next-line: typedef
  addImage() {
    this.popupAddImgIsOpen = true;
  }

  // tslint:disable-next-line: typedef
  closePopup() {
    this.popupAddImgIsOpen = false;
    this.popupApproveIsOpen = false;
  }

  // tslint:disable-next-line: typedef
  approvePartExport() {
    this.popupApproveIsOpen = true;
  }

  // tslint:disable-next-line: typedef
  rotateImage(id, srcUrl) {
    const imgStyle = document.getElementById(id);
    imgStyle.style.transform = 'rotate(180deg)translate(0%,50%)';

    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = srcUrl;

    // Create a canvas object.
    const canvas = document.createElement('canvas');

    // Wait till the image is loaded.
    img.onload = () => {
      rotateImage();
      saveImage(img.src.replace(/^.*[\\\/]/, ''));
    };

    const rotateImage = () => {
      // Create canvas context.
      const ctx = canvas.getContext('2d');

      // Assign width and height.
      canvas.width = img.width;
      canvas.height = img.height;

      ctx.translate(canvas.width / 2, canvas.height / 2);

      // Rotate the image and draw it on the canvas.
      // (I am not showing the canvas on the webpage.
      ctx.rotate(Math.PI);
      ctx.drawImage(img, -img.width / 2, -img.height / 2);
    };

    const saveImage = (imgName) => {
      const a = document.createElement('a');
      a.href = canvas.toDataURL('image/png');
      a.download = imgName;
      document.body.appendChild(a);
      a.click();
    };
  }
}

