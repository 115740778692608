<div class=" main-content">
    <div class=" header bg-gradient-icl py-7 py-lg-6 pt-lg-6">
        <div class=" container">
            <div class=" header-body text-center mb-7">
                <div class=" row justify-content-center">
                    <div class=" col-xl-5 col-lg-6 col-md-8 px-5">
                        <h1 class=" text-white">Welcome!</h1>

                        <!-- <p class=" text-lead text-white">
                            C.P.F.C
                        </p> -->
                        <img src="../../../assets/icons/LOGO-w.png">
                    </div>
                </div>
            </div>
        </div>

        <div class="separator separator-bottom separator-skew zindex-100">
            <svg x="0" y="0" viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1"
                xmlns="http://www.w3.org/2000/svg">
                <polygon class="fill-default" points="2560 0 2560 100 0 100"></polygon>
            </svg>
        </div>
    </div>

    <div class=" container mt--8 pb-5">
        <div class=" row justify-content-center">
            <div class=" col-lg-5 col-md-7">
                <div class=" card bg-secondary border-0 mb-0 login-card">
                    <div class=" card-header bg-transparent pb-5 text-left">

                        <!-- <form role="form" method="post"> -->
                        <div class="form-group mb-3" [ngClass]="{ focused: focus === true }">
                            <div class="input-group input-group-alternative">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="fas fa-user"></i></span>
                                </div>
                                <input class="form-control" [(ngModel)]="name" placeholder="User Name" type="text"
                                    name="uname" (focus)="focus = true" (blur)="focus = false" />
                            </div>
                        </div>
                        <div class="form-group" [ngClass]="{ focused: focus1 === true }">
                            <div class="input-group input-group-alternative">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><i class="ni ni-lock-circle-open"></i></span>
                                </div>
                                <input class="form-control" [(ngModel)]="password" placeholder="Password"
                                    type="password" name="psw" (focus)="focus1 = true" (blur)="focus1 = false" />
                            </div>
                        </div>
                        <span *ngIf="showErrorMsg" class="text-danger">your username or password is incorrect</span>
                        <div class="text-center">
                            <button type="submit" class="btn btn-default my-4" (click)="login()">
                                LOGIN
                            </button>
                        </div>
                        <!-- </form> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>